import { Box, Typography, styled } from "@material-ui/core";
import React, { FC } from "react";
import { ErrorMessage } from "../../forgot-password/src/ErrorMessage";
import CheckIcon from "@material-ui/icons/Check";
import Back from "@material-ui/icons/ArrowBackIosRounded";

interface Props {
  error: string;
  success: boolean;
  subTitle?: string;
  onTitleClick?: () => void;
  navigation: any;
}

const Title = styled(Typography)({
  fontSize: "30px",
  fontWeight: 600,
  fontFamily: "Poppins, Inter, sans-serif",
  marginTop: "27px",
  marginBottom: "27px",
  lineHeight: "27px",

  "@media(max-width: 900px)": {
    fontSize: "18px",
    fontWeight: 500,
    margin: "0"
  },
  "@media(max-width: 550px)": {
    fontSize: "18px",
    fontWeight: 500,
    margin: "0px",
    fontStyle: "normal",
    lineHeight: "normal"
  },
  "@media(max-width: 290px)": {
    fontSize: "16px"
  }
});

const Subtitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Poppins, Inter, sans-serif",
  marginLeft: "10px"
});

const TitleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  "@media(max-width: 900px)": {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: "relative",
    "& .mobileDesignclass": {
      marginBottom: 46
    }
  },
  "@media(max-width: 550px)": {
    marginTop: "15px",
    "& .mobileDesignclass": {
      marginBottom: 46
    }
  }
});
const AlertBox = styled(Box)({
  width: "100%",
  "@media(max-width: 900px)": {
    marginBottom: "20px"
  },
  "@media(max-width: 550px)": {
    marginBottom: "55px"
  }
});
const SuccessBox = styled(Box)({
  color: "#34D399",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  position: "absolute",
  top: "35px",
  "@media (max-width: 550px)": {
    left: "25px"
  }
});

const BackButton = styled(Back)({
  display: "none",
  "@media(max-width: 900px)": {
    display: "block",
    width: "20px",
    height: "20px",
    marginRight: "10px"
  },
  "@media(max-width: 550px)": {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    margin: "0px 0px 0px 25px"
  }
});

export const SettingsTitle: FC<Props> = ({
  error,
  success,
  subTitle,
  onTitleClick,
  navigation
}) => {
  return (
    <TitleWrapper>
      <Box
        data-test-id="goBack"
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
        style={{ ...(onTitleClick && { cursor: "pointer" }) }}
        className="mobileDesignclass"
      >
        <BackButton
          data-test-id="backButton"
          onClick={() => {
            if (onTitleClick) {
              window.location.pathname !== "/profile"
                ? onTitleClick()
                : navigation.navigate("LandingPage");
            }
          }}
        />
        <Title>User Settings</Title>
        {subTitle && <Subtitle>{subTitle}</Subtitle>}
      </Box>
      {window.innerWidth < 900 && (
        <>
          {error && (
            <AlertBox>
              <ErrorMessage errorMessage={error} showError={!!error} />
            </AlertBox>
          )}
          {success && (
            <SuccessBox>
              <Typography
                style={{
                  fontWeight: 500,
                  fontFamily: "Poppins, Inter, sans-serif"
                }}
              >
                Your changes have been saved
              </Typography>
              <CheckIcon style={{ paddingBottom: "7px" }} />
            </SuccessBox>
          )}
        </>
      )}
      <div />
    </TitleWrapper>
  );
};
