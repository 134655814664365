import React from "react";
import AccountController, { Props } from "./AccountController.web";
import { Formik } from "formik";
import * as Yup from "yup";
import { MobileSettingWrapper } from "./MobileSettingWrapper";
import { PhoneInput } from "./PhoneInput.web";

const PHONE_ERROR = "Please enter a valid US phone number.";
export default class MobilePhoneSetting extends AccountController {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <Formik
        initialValues={{
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          countryCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          company: this.state.company
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string()
            .required(PHONE_ERROR)
            .matches(/^\d{10}$/, PHONE_ERROR),
          countryCode: Yup.string().matches(/^\d{0,10}$/, PHONE_ERROR)
        })}
      >
        {({
          handleSubmit,
          setValues,
          values,
          errors,
          handleBlur,
          handleChange
        }) => (
          <MobileSettingWrapper
            goBack={this.props.navigation.goBack}
            navigation={this.props.navigation}
            onSubmit={() => handleSubmit()}
            subtitle="Phone"
            error={errors.countryCode! || errors.phoneNumber!}
            account={{
              id: localStorage.getItem("user-id"),
              name: this.fullName
            }}
            success={this.state.showSuccessMessage}
            onCancel={() => {
              setValues({
                company: this.state.company,
                lastName: this.state.lastName,
                countryCode: this.state.phoneCode,
                firstName: this.state.firstName,
                phoneNumber: this.state.phoneNumber
              });
            }}
          >
            <PhoneInput
              handleChangePhone={handleChange}
              handleChangeCode={handleChange}
              handleBlurCode={handleBlur}
              handleBlurPhone={handleBlur}
              phoneNumber={values.phoneNumber}
              countryCode={values.countryCode}
              error={errors.countryCode || errors.phoneNumber}
            />
          </MobileSettingWrapper>
        )}
      </Formik>
    );
  }
}
