import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string|null;
  newPass:string;
  conNewPass:string;
  passwordError:boolean;
  emailError:boolean;
  email: string|null;
  enablePasswordField: any;
  enableNewPassField:  any;
  enableConPassField:any;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  openSecModal:boolean;
  openChangePassModal:boolean;
  newPassError:boolean;
  conNewPassError:boolean;
  errorBlankFields:boolean;
  errorLoginResponse: boolean;
  errorBoxLoginText:string;
  changePassResponse:boolean;
  changePassErrorText:string;
  capitalLetterCheck:boolean;
  lowercaseCheck:boolean;
  atleastOneNumberCheck:boolean;
  minLengthCheck:boolean;
  tokenRequired:string;
  checkIcon:boolean;
  changePassComplete:boolean;
  screenSize: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  apiConfirmPassCallId:string = "";
  emailReg: RegExp;
  labelTitle: string = "";
   // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleResize = this.handleResize.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      emailError:false,
      password: "",
      newPass:"",
      conNewPass:"",
      passwordError:false,
      enablePasswordField: true,
      enableNewPassField: true,
      enableConPassField:true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      openSecModal: false,
      openChangePassModal:false,
      newPassError:false,
      conNewPassError:false,
      errorBlankFields:false,
      errorLoginResponse:false,
      errorBoxLoginText:"",
      changePassResponse:false,
      changePassErrorText:"",
      capitalLetterCheck:false,
      lowercaseCheck:false,
      atleastOneNumberCheck:false,
      minLengthCheck:false,
      tokenRequired:"",
      checkIcon:true,
      changePassComplete:false,
      screenSize: 0,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    
    if(this.state.email && this.state.password){
      this.setState({
        emailError:false,
        passwordError:false,
      })
    }
    this.getToken()
    this.setState({screenSize: window.innerWidth});
    window.addEventListener("resize", this.handleResize);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  
  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  }
  
  doEmailLogInValidation(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
       this.setState({ emailError: true });
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({ passwordError: true }); 
      return false;
    }
     this.setState({ emailError: false });
     this.setState({ passwordError: false });

    return true;
  }

 
  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.newPass !== this.state.newPass) {
      if(this.state.newPass.length>=8){
        this.setState({
          minLengthCheck:true,
        })
      }else{
        this.setState({
          minLengthCheck:false,
        })
      }
    }
  }
    

   handleLoginButton=()=>{

  
    if(!this.state.email && !this.state.password){
      this.setState({
        errorLoginResponse:true,
        emailError:true,
        passwordError:true,
        errorBoxLoginText:"Please enter your login credentials"   
      })
    }else if(!this.state.email){
      this.setState({
        errorLoginResponse:true,
        emailError:true,
        passwordError:false,
        errorBoxLoginText:"Please enter email address"   
      })
    }else if(!this.state.password){
      this.setState({
        errorLoginResponse:true,
        emailError:false,
        passwordError:true,
        errorBoxLoginText:"Please enter password"   
      })
    }
    else{
     
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
   const data = {
          attributes: {
              email:this.state.email,
              password:this.state.password
          }
  }
    const httpBody = {
      data: data,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

   this.apiEmailLoginCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/bx_block_login/login`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
    
  }
  }




  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => {
      this.doEmailLogInValidation();
      this.doEmailLogIn();
    },
  };
  

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowNewPass = () => {
    this.setState({
      enableNewPassField: !this.state.enableNewPassField,
    });
  };

  handleClickShowConPass = () => {
    this.setState({
      enableConPassField: !this.state.enableConPassField,
    });
  };

  setEmail = (text: string) => {
    const {email,password} = this.state;
    this.setState({
      email: text,
    });


    if(email){
      this.setState({
        emailError:false,
        passwordError:false,
        errorLoginResponse:false,
        errorBoxLoginText:""
      })
    }

    if(email && password){
      this.setState({
        emailError:false,
        passwordError:false,
        errorLoginResponse:false,
        errorBoxLoginText:""
      })
    }

    
  };

  setPassword = (text: string) => {
   
    const {email,password} = this.state;
    this.setState({
      password: text,
    });

    if(password){
      this.setState({
        passwordError:false,
      })
    }

    if(email && password){
      this.setState({
        errorLoginResponse:false,
        errorBoxLoginText:"",
        emailError:false,
        passwordError:false,
      })
    }
    
  };



  setNewPassword = (text: string) => {  
    this.setState({
      newPass: text,
      checkIcon: text.length <= 1,
      changePassResponse: false,
      changePassErrorText: "",
      newPassError: false,
      capitalLetterCheck: this.validateCapitalLetter(text),
      lowercaseCheck: this.validateLowercaseLetter(text),
      atleastOneNumberCheck: this.validateNumber(text),
    });
  };
  validateCapitalLetter = (str: string) => /[A-Z]/.test(str);
  validateLowercaseLetter = (str: string) => /[a-z]/.test(str);
  validateNumber = (str: string) => /\d/.test(str);
  

  setConfNewPassword = (text:string)=>{
    this.setState({
      conNewPass: text,
      conNewPassError: false,
    });
    
  }




  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

 

  handleSecurityModalClose=()=>{
    this.setState({ openSecModal: false })
     }

     handleOpenChangePassModal = ()=>{
      this.setState({
        openSecModal:false,
        openChangePassModal:true,
      })

    }

    handleChangePassModalClose=()=>{
      this.setState({
        openChangePassModal: false,
        changePassResponse:false,
        newPass:"",
        conNewPass:"",
        newPassError:false,
        conNewPassError:false,
        checkIcon: true,
        changePassErrorText: "",
        capitalLetterCheck:false,
        lowercaseCheck: false,
        atleastOneNumberCheck: false
      })
    }

       async   getToken (){
        let token :any = await getStorageData("token1");
        this.setState({tokenRequired:token})
        return this.state.tokenRequired
      }



 handleConfirmSubmit = (e:any)=>{
  if(!this.state.newPass && !this.state.conNewPass){
    this.setState({
      changePassResponse:true,
      newPassError:true,
      conNewPassError:true,
      changePassErrorText:"You must change your password in order to continue"   
    })
  }else if(!this.state.newPass){
    this.setState({
      changePassResponse:true,
      newPassError:true,
      conNewPassError:false,
      changePassErrorText:"Please enter new password"
    })
  }else if(!this.state.conNewPass){
    this.setState({
      changePassResponse:true,
      newPassError:false,
      conNewPassError:true,
      changePassErrorText:"Please enter confirm new password"
    })
  }else{
     const header = {
      "Content-Type": "application/json",
    };
   const data = {
          attributes: {
            token:this.state.tokenRequired,
            new_password:this.state.newPass,
            confirm_password:this.state.conNewPass,
          }
  }
    const httpBody = {
      data: data,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

   this.apiConfirmPassCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/bx_block_forgot_password/reset`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    }
  

}

    
 

 handleNavigatePrivacyPolicy = ()=>{
    
  this.props.navigation.navigate("PrivacyPolicy")
}

  handleNavigateForgotPassword = ()=>{
    
    this.props.navigation.navigate("ForgotPassword")
  }

  handleNavigateTermsConditions = ()=>{
    
    this.props.navigation.navigate("TermsAndConditions")
  }




  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            let changePassDone = responseJson.data.attributes.is_one_time_password_expired;
            if(changePassDone){
              localStorage.setItem("auth-token", responseJson.meta.token);
              localStorage.setItem("user-id", responseJson.data.id);
              localStorage.setItem("user-name", `${responseJson.data.attributes.first_name} ${responseJson.data.attributes.last_name}`);
              this.props.navigation.navigate("LandingPage")
            }else{
              await setStorageData("token1",responseJson.meta.token)
            this.setState({errorLoginResponse:false})
            this.setState({checkIcon:true})
            this.setState({openSecModal:true})
            this.getToken()
            localStorage.setItem("user-name", `${responseJson.data.first_name} ${responseJson.data.first_name}`);
            localStorage.setItem("auth-token", responseJson.meta.token);
            localStorage.setItem("user-id", responseJson.data.id);
            localStorage.setItem('user-name', `${responseJson.data.attributes.first_name} ${responseJson.data.attributes.first_name}`)
            }
        
          } else {
            // Check Error Response 
            this.setState({errorLoginResponse:true,errorBlankFields:false,
              errorBoxLoginText:"Your login credentials are incorrect",
              emailError:true,
              passwordError:true,
            })
          }

          this.parseApiCatchErrorResponse(errorReponse);
        
      }else if(apiRequestCallId === this.apiConfirmPassCallId){
       
          if (responseJson && responseJson.meta && responseJson.meta.message) {         
            this.setState({changePassResponse:false,
              openChangePassModal: false,
              openSecModal:false,
              newPass:"",
              conNewPass:"",
              password:"",
              checkIcon:true,
              newPassError:false,
              conNewPassError:false,
            })
                  
          } else {
            // Check Error Response  
            this.setState({changePassResponse:true,
              changePassErrorText:responseJson.errors[0]?.password,
              newPassError:true,
              conNewPassError:true,
            })
          }
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
