import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendRequest } from "../../forgot-password/src/utils";
import storage from "../../../framework/src/StorageProvider";
import React, { ChangeEvent } from "react";
import { getNavigationMessage } from "../../../components/src/toolkit";
import imageCompression from 'browser-image-compression';

interface RefObject {
  [key: string]: React.RefObject<HTMLHeadingElement>;
}
export interface SurveyInfoField {
  id: number;
  field_name: string;
  field_type: "text" | "numeric" | "select" | "checkbox" | "radio" | "dynamic_dropdown";
  field_options: string[];
  is_mandatory: boolean;
  dynamic_dropdown_type: string;
  is_speech_to_text_required: boolean;
  is_upload_image_video_required: boolean;
}

export interface SurveyInfoSection {
  id: number;
  survey_info_section_name: string;
  add_similar_section: boolean;
  add_similar_section_text: string;
  copy_from_last_filled: boolean;
  survey_info_section_fields: SurveyInfoField[];
  newMedia: Array<PhotosNew>;
}

interface SurveyAttributes {
  id: number;
  template_name: string;
  is_activated: boolean;
  survey_info_section: SurveyInfoSection[];
}

export interface SurveyResponse {
  survey_template_details: {
    data: {
      id: string;
      type: string;
      attributes: SurveyAttributes;
    };
  };
}

export type CustomFormLink = Omit<
  SurveyInfoSection,
  "survey_info_section_fields"
> & {
  isSelected: boolean;
};

interface SaveSurveyResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      survey_name: string;
      date_created: string;
      last_edited: string;
      created_by: string;
      owner: string;
      progress: unknown;
      user_survey_sections: {
        id: number;
        user_survey_section_name: string;
        user_survey_sub_sections: {
          id: number;
          user_survey_sub_section_name: string;
          user_survey_sub_section_details: {
            id: number;
            field_name: string;
            field_value: string | string[];
          }[];
        }[];
      }[];
    };
  };
}



export interface ValidResponseType {
  data: object;
}
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}

export interface SureveySubSection {
  surevey_sub_section_details: Array<CopyPayloadType>
}

export interface CopyPayloadType {
  id: number,
  user_survey_sub_section_id: number,
  field_name: string,
  field_value: null,
  created_at: string,
  updated_at: string,
  photo: null
}

export interface AutoSaveType {
  user_survey_id: number,
  survey_template_id: number,
  survey_name: string,
  user_survey_sections_attributes: [
    {
      id: number,
      user_survey_section_name: string,
      user_survey_sub_sections_attributes: [
        {
          id: number,
          user_survey_sub_section_name: string,
          user_survey_sub_section_details_attributes: [
            {
              id: number,
              field_name: string,
              field_value: string
            }
          ]
        }
      ]
    }
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface ResponseJson {
  id: string;
  attributes: {};
}
export type Dropdown = any;

export interface ApidataResponseMock {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface ApidataResponseSubsectionDelete {
  contentType: string;
  method: string;
  endPoint: string;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  data: object;
}
export interface InvalidResponseType {
  errors: string;
}
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}

interface CreateSurveyUsers {
  data: {
    id: number,
    type: string,
    attributes: {
      id: number,
      survey_template_id: number,
      survey_name: string,
      date_created: string,
      last_edited: string,
      created_by: string,
      owner: string,
      progress: null | string,
      user_survey_sections: Array<UserSurveySections>
    }
  }
}

interface CopyFromLastFilledResponse {
  user_survey_sub_section_details: {
    field_value_multiple: [];
    field_type: string;
    id: number;
    user_survey_sub_section_id: number;
    field_name: string;
    field_value: string;
  }[]
}

interface UserSurveySections {
  id: number,
  user_survey_section_name: string,
  user_survey_sub_sections: Array<UserSurveySubSections>
}

interface UserSurveySubSections {
  id: number,
  user_survey_sub_section_name: string,
  user_survey_sub_section_details: Array<UserSurveySubSectionDetails>
}
interface UserSurveySubSectionDetails {
  videos: Array<Photos>;
  field_type: string;
  field_options: string[];
  id: number,
  field_name: string,
  field_value: string | null,
  field_value_multiple: string[],
  photos: Array<Photos>;
}

interface UserSurveyDetails {
  userSurveySecId: number;
  userSurveySecName: string;
  userSurveySubSecId: number;
  userSurveySubSecName: string;
  userSurveyDetails: UserSurveySubSectionDetails;
}

interface UpdateUserSurvey {
  data: {
    id: number,
    type: string,
    attributes: {
      id: number,
      survey_name: string,
      date_created: string,
      last_edited: string,
      created_by: string,
      owner: string,
      progress: string,
      user_survey_sections: [
        {
          id: number,
          user_survey_section_name: string,
          user_survey_sub_sections: [
            {
              id: number,
              user_survey_sub_section_name: string,
              user_survey_sub_section_details: [
                {
                  id: number,
                  field_name: string,
                  field_value: null,
                  photos: []
                }
              ]
            }
          ]
        }
      ]
    }
  },
  meta: {
    message: string
  }
}

interface FieldDetails {
  data: {
  survey_field_detail: {
      id: number,
      user_survey_sub_section_id: number,
      field_name: string,
      field_value: null,
      created_at: string,
      updated_at: string,
      photo: null,
      field_type: null,
      field_options: null
   }
 } 
}
export interface SubsectionAcc {
  subsectionId: string | null;
  subsectionName: string;
  isOpen: boolean;
}

interface SubsectionsAccordion  {
  [sectionId: number]: SubsectionAcc[],
}

interface BulkUploadingFiles {
  data: {
    id: number;
    type: string;
    attributes: {
      id: number;
      user_survey_sub_section_detail_id: number;
      photo_details: Array<Photos>;
      media_details: Array<Photos>;
      created_at: string;
      updated_at: string;
    }
  }
}

export interface Photos {
  id: number;
  filename: string;
  content_type: string;
  created_at: string;
  byte_size: number;
  url: string;
  description: string | null;
  fieldId?: number;
  subSectionNew?: string;
  media_id: number;
}
export interface PhotosNew {
  id: number;
  filename: string;
  content_type: string;
  created_at: string;
  byte_size: number;
  url: string;
  media_id: number;
  subSectionNew: string;
  description: string;
  fieldId: number;
}

interface DeleteFileResponse {
  data: {
  message: string; 
  }
}
interface UploadedFiles {
  [key: string]: File[];
};
interface UploadedFilesNew {
  [key: string]: BulkUploadingFiles[];
};

interface CustomFile {
  file: File;
}

export interface PhotosState {
  [key: string]: Photos[];
}
interface DynamicFieldOptionsResponse {
  data: {
    user_survey: {
        id: number | string,
        survey_name: string,
        created_by: string,
        owner_name: string,
        survey_template_id: number | string,
        status: string,
        is_deleted: null | boolean | string,
        created_at: string,
        updated_at: string,
        account_id: number | string
    },
    survey_associated_buildings: string[] | [],
    survey_associated_floors: string[] | []
  }
}

interface UserSurveySectionAttributes {
  id: number;
  user_survey_section_name: string;
  user_survey_sub_sections_attributes: UserSurveySubSectionAttributes[];
}

interface SubSectionDetailAttributes {
  id: number,
  field_name: string,
  field_value: string[] | string;
  photos: Array<Photos>;
}

interface UserSurveySubSectionAttributes {
  id: number;
  user_survey_sub_section_name: string;
  user_survey_sub_section_details_attributes: SubSectionDetailAttributes[];
}

// Customizable Area End

interface S {
  // Customizable Area Start
  startTime: number;
  screenSize: number;
  surveyAttributes?: SurveyAttributes | null;
  currentSectionId: number;
  sections: SurveyInfoSection[];
  currentSection?: SurveyInfoSection | null;
  currentFields: SurveyInfoField[];
  error: string;
  openAccordions: SubsectionsAccordion;
  openAccordionsOpen: boolean;
  fieldValues: {
    [section: string]: {
      [subSection: string]: {
        [field: string]: string | string[];
      };
    };
  };
  isMandatoryError: {
    [section: string]: {
      [subSection: string]: {
        [field: string]: boolean;
      };
    };
  };
  showPopUp: boolean;
  searchSpecificSec:string;
  saveBtnResponse:boolean;
  specificSecSearchesData:any;
  userSurveyId:string;
  userSurveySubSectionId:string;
  sectionSubSectionList:any;
  allFields: string[];
  currentSectionName:string | undefined,
  userSurveySectionFields:any,
  createuser: CreateSurveyUsers;
  userSurveySecName: string;
  userSurveySubSecName: string;
  userSurveyDetails: UserSurveySubSectionDetails;
  updatedValuesArray: UserSurveyDetails[];
  navigationSearchSections:any,
  listOfSectionsSubsections:any,
  selectedGlobalSearchOption: string | null;
  autoSaveResponse: object;
  copyLastFilledData: boolean;
  linksArr:any,
  deleteSubSecPopup:boolean,
  subSecName:string,
  openAccordionsNewArr:any,
  surveyResponse: object;
  openUploadModal: boolean;
  uploadingLoader: boolean;
  uploadValidationError: string;
  openProgressBar: boolean;
  startDownloading: boolean;
  totalFiles: number;
  fileLimitExceeded: boolean;
  progressBar: number;
  limitValidationError: string;
  limitVideoValidationError: string;
  uploadSuccessfully: boolean;
  isUploadingTrue: boolean;
  formData: FormData;
  uniqueId: number | null;
  fieldDetails: FieldDetails; 
  bulkUploadingResponse: BulkUploadingFiles;
  newData: number;
  photos: Array<Photos>;
  medias: Array<Photos>;
  mergeMedia: Array<PhotosNew>;
  deleteMediaItemIndex: number;
  deleteFileResponse: DeleteFileResponse;
  deleteFileConfirmationPopup: boolean;
  fileFormat: string;
  fileId: PhotosNew;
  fileUploadindex: number;
  addSimilarResponse: CreateSurveyUsers;
  multipleSubSectionId: number;
  hasOpenSidebar:boolean;
  isFileContainerOpen: boolean;
  isPermissionModalOpen: boolean;
  isGalleryAccessAllowed: boolean;
  ispermissionGranted: boolean;
  isCameraModalOpen: boolean;
  subSectionNew: string;
  uploadedFiles: { [key: string]: File[] },
  uploadedImages: UploadedFiles;
  uploadedVideos: UploadedFiles;
  selectedImages: UploadedFiles;
  selectedVideos: UploadedFiles;
  uploadedDocument: UploadedFilesNew;
  photosUpdated: PhotosState;
  uploadProgress: number[];
  uploadSpeed: string[];
  dynamicOptions: DynamicFieldOptionsResponse;
  option:string;
  subsectionName: string,
  subsectionId:number

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  needsUpdate = false;
  getTemplatesMessageID = "";
  saveButtonMessageID = "";
  sectionListMessageId="";
  searchSurveyDetailsMessageID = ""; // Add a message ID for the search request
  globalSearchMessageId = '';
  submittedSurvey?: SaveSurveyResponse = undefined;
  getSectionListId="";
  getAllSectionListId="";
  getSurveyApiCallId: string = "";
  createSurveyApiCallId: string = "";
  saveSectionDataApi: string = "";
  navigationSearchApiCallId:string="";
  userSurveySectionNewId:string="";
  userSurveySubSectionNewId:string="";
  completeListOfSectionsId:string="";
  getDynamicOptionsId:string="";
  autoSaveApiCallId: string = "";
  copyFromLastFilledId: string = "";
  addSectionApiCallId: string = "";
  newAccordionsArr:any=[];
  deleteSubsectionApiCallId: string = "";
  getFieldDetailApiCallId: string = "";
  bulkUploadingApiCallId: string = "";
  copyMediaDataId: string = "";
  deleteFileUploadingApiCallId: string = "";
  deleteCopyFileUploadingApiCallId: string = "";
  addDescriptionApiCallId: string = "";
  headingRefs: RefObject = {};
  getSurveyDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      startTime: performance.now(),
      screenSize: 600,
      surveyAttributes: null,
      currentSectionId: 0,
      currentFields: [],
      currentSection: {
        id: 0,
        survey_info_section_name:"",
        add_similar_section: false,
        add_similar_section_text: "",
        copy_from_last_filled: false,
        newMedia: [],
        survey_info_section_fields:[{
          id: 0,
          field_name: "",
          field_type: "text" ,
          field_options: [""],
          is_mandatory: false,
          dynamic_dropdown_type: '',
          is_speech_to_text_required: false,
          is_upload_image_video_required: false
        }]
      },
      sections: [],
      error: "",
      openAccordions: {},
      openAccordionsOpen: false,
      fieldValues: {},
      isMandatoryError: {},
      showPopUp: false,
      searchSpecificSec:"",
      saveBtnResponse:false,
      specificSecSearchesData:[],
      userSurveyId:"",
      userSurveySubSectionId:"",
      sectionSubSectionList:[],
      allFields: [],
      currentSectionName:"",
      userSurveySectionFields:[],
      userSurveySecName: "",
      userSurveySubSecName: "",
      selectedGlobalSearchOption: null,
      copyLastFilledData: false,
      userSurveyDetails: {
        id: 0,
        field_name: "",
        field_value: null,
        photos: [],
        videos: [],
        field_options: [],
        field_value_multiple: [],
        field_type:''
      },
      createuser: {
        data: {
          id: 0,
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            survey_template_id: 0,
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: null,
            user_survey_sections: [
              {
                id: 0,
                user_survey_section_name: "",
                user_survey_sub_sections: [
                  {
                    id: 0,
                    user_survey_sub_section_name: "",
                    user_survey_sub_section_details: [
                      {
                        id: 0,
                        field_name: "",
                        field_value: null,
                        photos: [],
                        videos: [],
                        field_options:[],
                        field_type:'',
                        field_value_multiple: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      },
      updatedValuesArray: [],
      navigationSearchSections:[],
      listOfSectionsSubsections:[],
      autoSaveResponse: {},
      linksArr:[],
      deleteSubSecPopup:false,
      subSecName:"",
      openAccordionsNewArr:[],
      surveyResponse: {},
      openUploadModal: false,
      uploadingLoader: false,
      uploadValidationError: "",
      openProgressBar: false,
      startDownloading: false,
      totalFiles: 0,
      fileLimitExceeded: false,
      progressBar: 10,
      limitValidationError: "",
      limitVideoValidationError: '',
      uploadSuccessfully: false,
      isUploadingTrue: false,
      formData: new FormData(),
      uniqueId: 0,
      fieldDetails: {
        data: {
        survey_field_detail: {
          id: 0,
          user_survey_sub_section_id: 0,
          field_name: "",
          field_value: null,
          created_at: "",
          updated_at: "",
          photo: null,
          field_type: null,
          field_options: null
         }
        }
      },
      bulkUploadingResponse: {
        data: {
          id: 0,
          type: "",
          attributes: {
            id: 0,
            user_survey_sub_section_detail_id: 0,
            photo_details: [
              {
                id: 0,
                filename: "",
                content_type: "",
                created_at: "",
                byte_size: 0,
                url: "",
                description: '',
                media_id:0
              }
            ],
            media_details: [
              {
                id: 0,
                filename: "",
                content_type: "",
                created_at: "",
                byte_size: 0,
                url: "",
                description: '',
                media_id:0
              }
            ],
            created_at: "",
            updated_at: ""
          }
        }
      },
      newData: 0,
      photos: [{
        id: 0,
        filename: "",
        content_type: "",
        created_at: "",
        byte_size: 0,
        url: "",
        description: '',
        media_id:0
      }],
      medias: [{
        id: 0,
        filename: "",
        content_type: "",
        created_at: "",
        byte_size: 0,
        url: "",
        description: '',
        media_id:0
      }],
      mergeMedia: [],
      deleteMediaItemIndex:NaN,
      deleteFileResponse: {
        data: {
        message: ""
        }
      },
      deleteFileConfirmationPopup: false,
      fileFormat: "",
      fileId: {
        id: 0,
        filename: "",
        content_type: "",
        created_at: "",
        byte_size: 0,
        url: "",
        media_id: 0,
        subSectionNew: "",
        description:'',
        fieldId:0
      },
      fileUploadindex: 0,
      addSimilarResponse: {
        data: {
          id: 0,
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            survey_template_id: 0,
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: null,
            user_survey_sections: [
              {
                id: 0,
                user_survey_section_name: "",
                user_survey_sub_sections: [
                  {
                    id: 0,
                    user_survey_sub_section_name: "",
                    user_survey_sub_section_details: [
                      {
                        id: 0,
                        field_name: "",
                        field_value: null,
                        photos: [],
                        videos:[],
                        field_type: '',
                        field_options: [],
                        field_value_multiple: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      },
      multipleSubSectionId: 0,
      isPermissionModalOpen: false,
      isGalleryAccessAllowed: true,
      ispermissionGranted: false,
      isCameraModalOpen: false,
      hasOpenSidebar:false,
      isFileContainerOpen: true,
      subSectionNew: "",
      uploadedFiles: {},
      uploadedImages: {},
      uploadedVideos: {},
      selectedImages: {},
      selectedVideos: {},
      uploadedDocument: {},
      photosUpdated: {},
      uploadProgress: [],
      uploadSpeed: [],
      dynamicOptions: {
        data: {
          user_survey: {
            id: "",
            survey_name: "",
            created_by: "",
            owner_name: "",
            survey_template_id: "",
            status: "",
            is_deleted: null,
            created_at: "",
            updated_at: "",
            account_id: ""
          },
          survey_associated_buildings: [],
          survey_associated_floors: []
        }
      },
      option:"",
      subsectionName:"",
      subsectionId:0


      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (this.isMatchingMessage(this.getTemplatesMessageID, dataMessage)) {
        this.handleTemplateResponse(message);
    } else if (this.isMatchingMessage(this.getSectionListId, dataMessage)) {
        this.handleSectionListResponse(successMessage);
    } else if (this.isMatchingMessage(this.globalSearchMessageId, dataMessage)) {
        this.handleGlobalSearchResponse(message);
    } else if (this.isMatchingMessage(this.copyFromLastFilledId, dataMessage)) {
        this.handleCopyLastFilledResponse(message);
    } else if (this.isMatchingMessage(this.navigationSearchApiCallId, dataMessage) || this.isMatchingMessage(this.completeListOfSectionsId, dataMessage)) {
        this.handleNavigationOrCompleteListResponse(dataMessage, successMessage);
    } else if (this.isMatchingMessage(this.getDynamicOptionsId, dataMessage)) {
        this.handleDynamicOptionsResponse(successMessage);
    }
    else if (this.isMatchingMessage(this.getSurveyDetailsCallId, dataMessage)) {
      const success = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      await this.getSurveyData(success);
      
    }
    
    this.apiReceiver(message);
    // Customizable Area End
  }

  // Customizable Area Start
  
  private isMatchingMessage(messageId: string, dataMessage: string): boolean {
    return messageId === dataMessage;
  }

  private handleTemplateResponse(message: Message): void {
      this.handleResponse(
          message,
          (success) => this.handleSuccessGetTemplateResponse(success),
          (errors) => this.setState({ error: errors[0], showPopUp: true }),
      );
  }

  private handleSectionListResponse(successMessage: any): void {
      if (this.handleApiResponseNew(successMessage, (success: any) => ({
          sectionSubSectionList: success?.data,
          showPopUp: false,
      }))) {
          return;
      }
  }

  private handleGlobalSearchResponse(message: Message): void {
      this.handleResponse(
          message,
          (success) => {
            const sectionName = success.searched_field_details.data.attributes.user_survey_section_name;
            let sectionId;
            let subsectionNavigateField;
            if (sectionName) {
                sectionId = this.state.sections.find(section => sectionName === section.survey_info_section_name)?.id;
            } else {
                sectionId = success.searched_field_details.data.attributes.user_survey_section_id;
                subsectionNavigateField=success.searched_field_details.data.attributes.user_survey_sub_section_name;
            }
            this.onLickClick(sectionId,subsectionNavigateField);
          },
          (errors) => this.setState({ error: errors[0], showPopUp: true }),
      );
  }

  private handleCopyLastFilledResponse(message: Message): void {
      this.handleResponse(
          message,
          (success) => this.handleCopyLastFilledSuccessResponse(success.data.attributes),
          (errors) => this.setState({ error: errors[0], showPopUp: true }),
      );
  }

  private handleNavigationOrCompleteListResponse(dataMessage: string, successMessage: any): void {
      const successHandler = (success: any) => ({
          [this.navigationSearchApiCallId === dataMessage ? 'navigationSearchSections' : 'listOfSectionsSubsections']: success?.user_survey_navigation_menu?.user_survey_sections,
          showPopUp: false,
      });

      if (this.handleApiResponseNew(successMessage, successHandler)) {
          return;
      }

      if (this.navigationSearchApiCallId === dataMessage) {
          this.getListOfSectionSubSection();
      } else {
          this.sendGetTemplatesRequest();
      }
  }

  private handleDynamicOptionsResponse(successMessage: any): void {
      this.setState({ dynamicOptions: successMessage });
  }

  async componentDidMount() {
    this.setState({screenSize: window.innerWidth});
    window.addEventListener("resize", this.handleResize);
    const token = await storage.get("auth-token");
   if (!token) {
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
      return;
    }
    let createUserResponse = await storage.get("createUserResponse");
    let createUserResponseParse = JSON.parse(createUserResponse);
    this.fetchSurveyDetails();
    this.updateCustomFormFunction();
    if (!createUserResponseParse){
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
      return;
    }
  }

  componentDidUpdate(
    _: Readonly<Props>,
    __: Readonly<S>,
    ___?: SS | undefined
  ): void {
    if(this.state.currentFields !== __.currentFields){
      this.getSurveyData(this.state.addSimilarResponse);
    }
    const currSectionId = Number(this.extractFromQueryParams("sectionId"));
    const attributes = this.state.surveyAttributes;
    const surveySectionSelected = this.customFormLinks.find((field:any)=>currSectionId==field.id)
    const {searchSpecificSec} = this.state;
    const currentSectionId = Number(this.extractFromQueryParams("sectionId"))
    if(this.state.searchSpecificSec !== searchSpecificSec){
      this.navigationSearchWithinSection()
    }
    if (
      this.state.currentSectionId !== Number(currentSectionId) &&
      attributes &&
      this.needsUpdate
    ) {
      this.needsUpdate = false;
      const currentSection = attributes.survey_info_section.find(
        (section) => section.id === currSectionId
      );
      if (!currentSection) {
        this.setState({
          showPopUp: true,
          error: 'Invalid template or section id',
        })
        return;
      }
      const sectionName = currentSection.survey_info_section_name;
      const currFieldsValues = this.state.fieldValues[sectionName];
      if (!currFieldsValues) {
        this.state.fieldValues[sectionName] = {};
      }
      this.setState({
        currentSectionId: Number(currentSectionId),
        currentFields: currentSection?.survey_info_section_fields || [],
        currentSection,
        currentSectionName:surveySectionSelected?.survey_info_section_name,
      });
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  updateCustomFormFunction = () => {
    this.sendGetTemplatesRequest();
    this.completeListOfSections();
    this.getListOfSectionSubSection();
  }
  
  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  }

   handleApiResponseNew = (success:any, setStateCallback:any) => {
    if (!success) {
      this.setState({ error: "An unexpected error occurred", showPopUp: true });
      return true;
    }
  
    this.setState(setStateCallback(success));
    return false;
  };

   

  apiReceiver = (message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
      }
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  apiSuccessCallBacks = (
    apiRequestCallID: string,
    responseJson: UpdateUserSurvey & FieldDetails & BulkUploadingFiles & DeleteFileResponse & CreateSurveyUsers
  ) => {
    if (apiRequestCallID === this.createSurveyApiCallId) {
     this.setState({
      surveyResponse: responseJson
     })
    }
    if (apiRequestCallID === this.autoSaveApiCallId) {
      this.setState({
        autoSaveResponse:responseJson,
        addSimilarResponse: responseJson
      });
    storage.set("createUserResponse",JSON.stringify(responseJson));
    }
    if (apiRequestCallID === this.getFieldDetailApiCallId) {  
      this.setState({
        fieldDetails:responseJson
      })
    }  
    if (apiRequestCallID === this.deleteFileUploadingApiCallId) {
      this.handleDeleteResponseSuccess(responseJson, false);
      this.setState({
        deleteFileResponse:responseJson
      });
    }
    if (apiRequestCallID === this.deleteCopyFileUploadingApiCallId) {
      this.handleDeleteResponseSuccess(responseJson, true);
      this.setState({
        deleteFileResponse:responseJson
      });
    }
    if (apiRequestCallID === this.bulkUploadingApiCallId) {
      this.setState({
       bulkUploadingResponse:responseJson
      },()=>{
        this.handleFilesData();
      });
   }
   if (apiRequestCallID === this.copyMediaDataId) {
      this.manageCopyMediaStates(responseJson);
   }
    if (apiRequestCallID === this.saveSectionDataApi) {
      this.setState({
        addSimilarResponse: responseJson
      });
    storage.set("createUserResponse",JSON.stringify(responseJson));
      this.updateCustomFormFunction();
   }
   if(apiRequestCallID === this.deleteSubsectionApiCallId){
    this.setState({
      addSimilarResponse: responseJson
    });
    storage.set("createUserResponse",JSON.stringify(responseJson));
      this.updateCustomFormFunction();
   }
  };

  handleEror = (error: string) => {
    this.setState({
      showPopUp: true,
      error,
    })
  }
  apiCallSurvey = async (data: ApidataResponseMock) => {
    const token = await storage.get("auth-token");
    let { contentType, method, endPoint, body ,type} = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == "formData" ? body:JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiCallSubsectionDelete = async (data: ApidataResponseSubsectionDelete) => {
    const token = await storage.get("auth-token");
    let { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  
findSectionIdBySubSecId =(sectionId: number)=> {
  let result = this.state.addSimilarResponse.data.attributes.user_survey_sections.flatMap((item)=>{
    return item.id;
})
if(result.includes(sectionId)){
    this.setState({subSecName:''})
    return sectionId;
} else {
    for (const sections of this.state.addSimilarResponse.data.attributes.user_survey_sections) {
      for (const subSection of sections.user_survey_sub_sections) {
          if(subSection.id == sectionId){
          this.setState({userSurveySubSectionId: subSection.id.toString(), subSecName: subSection.user_survey_sub_section_name})
              return sections.id;
          }
      }
    }
}}
  
  onLickClick = async(sectionId: number | undefined,subsectionNavigateField?:string) => {
    this.needsUpdate = true;
    const newSectionIdReq = sectionId ? await this.findSectionIdBySubSecId(sectionId) : this.extractFromQueryParams("sectionId");
    this.props.navigation.navigate("CustomForm", {
      sectionId: newSectionIdReq,
      templateId: this.extractFromQueryParams("templateId"),
      surveyId: this.extractFromQueryParams("surveyId"),
      sectionIdReq:newSectionIdReq,
      selectedGlobalSearchOption: this.state.selectedGlobalSearchOption,
    });
    this.fetchSurveyDetails()
    this.getListOfSectionSubSection()
    this.setState({
      searchSpecificSec:""
    });
    subsectionNavigateField && this.handleScrollToHeading(subsectionNavigateField)
  };

  extractFromQueryParams(param: string) {
    const sectionId = this.props.navigation.getParam(param);
    return sectionId;
  }

  get customFormLinks(): CustomFormLink[] {
    return this.state.sections.map((section) => ({
      ...section,
      isSelected: section.id === this.state.currentSectionId,
    }));
  }
 

  get linksSectionsSubsections():any{
    return   this.state.listOfSectionsSubsections.map((section:any) => ({
      ...section,
      isSelected: section.id === this.state.currentSectionId,
    }));
  }



  get hasNextSection() {
    return this.currentSectionIndex !== this.state.sections.length - 1;
  }

  sendGetTemplatesRequest = () => {
    const templateId = this.props.navigation.getParam("templateId");
    if (!templateId) {
      return;
    }
    sendRequest(
      `/bx_block_survey/survey_template/survey_template_details?survey_template_id=${templateId}`,
      "GET",
      (id) => (this.getTemplatesMessageID = id),
      null,
      {
        token: localStorage.getItem("auth-token"),
      }
    );
  };

  completeListOfSections = async()=>{
    const surveyID = this.extractFromQueryParams("surveyId");
    const token = await storage.get("auth-token")
      sendRequest(
        `bx_block_survey/users_survey/user_survey_section_navigation?user_survey_id=${surveyID}`,
        "GET",
        (id) => (this.completeListOfSectionsId = id),
        null,
        {
          token,
        }
      );
    }; 

  navigationSearchWithinSection = async()=>{
    const {userSurveyId,searchSpecificSec} = this.state;
    if(searchSpecificSec.length > 0)
    {const token = await storage.get("auth-token")
      sendRequest(
        `/bx_block_survey/users_survey/user_survey_section_navigation?user_survey_id=${userSurveyId}&search_query=${searchSpecificSec}`,
        "GET",
        (id) => (this.navigationSearchApiCallId = id),
        null,
        {
          token,
        }
      );}
    else {
      this.setState({ navigationSearchSections: [] });
    }
    };

 getListOfSectionSubSection = async()=>{
  const {navigationSearchSections, userSurveySubSectionId} = this.state;
  let sectionId = this.extractFromQueryParams("sectionId");
  const token = await storage.get("auth-token")
  if(navigationSearchSections && userSurveySubSectionId){
  this.setState({showPopUp:false})
  sendRequest(
    `bx_block_survey/users_survey/user_survey_section_details?user_survey_section_id=${sectionId}&user_survey_id=${this.state.userSurveyId}&user_survey_sub_section_id=${userSurveySubSectionId}`,
    "GET",
    (id) => (this.getSectionListId = id),
    null,
    {
      token,
    }
  );
  } else return;
  };

  getDynamicOptionsApiCall = async()=>{
    let surveyId= this.extractFromQueryParams("surveyId");
    const token = await storage.get("auth-token");
    sendRequest(
      `bx_block_survey/users_survey/get_survey_associated_building_floors?user_survey_id=${surveyId}`,
      "GET",
      (id) => (this.getDynamicOptionsId = id),
      null,
      {
        token,
      }
    );
    };

  handleNextButtonClick = () => {
    this.needsUpdate = true;
    const sectionId = this.state.sections[this.currentSectionIndex + 1]?.id;
    window.scrollTo(0, 0);
    this.props.navigation.navigate("CustomForm", {
      sectionId,
      templateId: this.extractFromQueryParams("templateId"),
      surveyId: this.extractFromQueryParams("surveyId"),
    });
  };
  handleBackButtonClick = () => {
    this.props.navigation.navigate("LandingPage")
    this.needsUpdate = true;
    const sectionId = this.state.sections[this.currentSectionIndex - 1]?.id;
    window.scrollTo(0, 0);
    if (sectionId) {
      this.props.navigation.navigate("CustomForm", {
        sectionId,
        templateId: this.extractFromQueryParams("templateId"),
        surveyId: this.extractFromQueryParams("surveyId"),
      });
    }
  }


  handleFieldChange = (
    subSectionName: string,
    fieldName: string,
    value: string | string[],
    dynamic_dropdown_type?: string
  ) => {
    // @ts-ignore
    const sectionName = this.state.currentSection.survey_info_section_name;
    let sectionValues = this.state.fieldValues[sectionName];
    if(dynamic_dropdown_type && (dynamic_dropdown_type === 'Floor' || dynamic_dropdown_type === 'Building')){
    this.updateFieldOptions(fieldName, dynamic_dropdown_type)
    }
    if(!subSectionName){
      subSectionName = sectionName
    }
    if (!sectionValues) {
      sectionValues = { [subSectionName]: {} };
    }
    if(value.length>0 && this.state.isMandatoryError[sectionName]?.[subSectionName]?.[fieldName]){
      this.state.isMandatoryError[sectionName][subSectionName][fieldName] = false;
      this.setState({isMandatoryError: this.state.isMandatoryError})
    }
    let subSectionValues = sectionValues[subSectionName];
    if (!subSectionValues) {
      sectionValues = {
        ...sectionValues,
        [subSectionName]: {},
      };
    }
    this.setState({
      fieldValues: {
        ...this.state.fieldValues,
        [sectionName]: {
          ...sectionValues,
          [subSectionName]: {
            ...sectionValues[subSectionName],
            [fieldName]: value,
          },
        },
      },
    });
  };

  handleAccordionToggle = (subSectionName: string) => {
    const {currentSectionId} = this.state;
    let newStateAccodions: Pick<S, 'openAccordions'>;
    const {[currentSectionId]: currentAccordion, ...rest} = this.state.openAccordions;
    const newAccordions = currentAccordion?.map((val) => {
      if (subSectionName === val.subsectionName) {
        return {
          ...val,
          isOpen: !val.isOpen,
        }
      }
      return val;
    });
    newStateAccodions = {
      openAccordions: {
        ...rest,
        [currentSectionId]: newAccordions,
      }
    }
    this.setState(newStateAccodions);
  };

  checkEmpty=(obj: { [x: string]: string | any })=>{
    for(let key in obj){
      if(obj[key] instanceof Object === true){
        if(this.checkEmpty(obj[key]) === false) return false;
      }
      else{
        if(obj[key] && obj[key].length !== 0) return false;
      }
    }
    return true;
  }

  modifyKey = (obj: { [x: string]: any; }, oldKey: string, newKey: string)=> {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        this.modifyKey(obj[key], oldKey, newKey);
      }
      if (key === oldKey) {
        obj[newKey] = obj[key];
        delete obj[key];
      }
    }
    return obj;
  }

  areAllValuesFalse = (obj: any): boolean => {
    for (const key in obj) {
        const value = obj[key];
        if (typeof value === 'object') {
            if (!this.areAllValuesFalse(value)) {
                return false;
            }
        } else if (value !== false) {
            return false;
        }
    }
    return true;
  }

  checkAllValuesFalse = (obj: any): boolean => {
      for (const key in obj) {
          const value = obj[key];
          if (typeof value === 'object') {
              if (!this.areAllValuesFalse(value)) {
                  return false;
              }
          } else if (value !== false) {
              return false;
          }
      }
      return true;
  }

  checkMandatoryFields = (currentSectionName: string) => {
    const updatedFieldValues: {
      [section: string]: {
        [subSection: string]: {
          [field: string]: boolean;
        };
      };
    } = {};
    const { fieldValues, currentFields } = this.state;
    if (!fieldValues[currentSectionName]) {
      return updatedFieldValues;
    }
    const sectionFieldValues = fieldValues[currentSectionName];
    for (const subSectionName in sectionFieldValues) {
      updatedFieldValues[currentSectionName] = updatedFieldValues[currentSectionName] || {};
      updatedFieldValues[currentSectionName][subSectionName] = {};
      const subSectionFieldValues = sectionFieldValues[subSectionName];
      for (const fieldName in subSectionFieldValues) {
        const field = currentFields.find((f) => f.field_name === fieldName);

        if (field) {
          const isEmpty = field.is_mandatory && (subSectionFieldValues[fieldName].length===0);
          updatedFieldValues[currentSectionName][subSectionName][fieldName] = isEmpty;
        }
      }
    }
    return updatedFieldValues;
  };

  manageMandatoryFields = async() => {
    const currentSectionName = this.state.currentSection?.survey_info_section_name || '';
    const updatedFieldValues =  this.checkMandatoryFields(currentSectionName);
    const allValuesFalseForAllObjects = this.checkAllValuesFalse(updatedFieldValues);
    if(!allValuesFalseForAllObjects){
      this.setState({isMandatoryError: updatedFieldValues})
        return true;
    }
    return false;
  }

  addNewSection = async() => {
    const {
      currentSectionId: sectionId,
      fieldValues,
      currentSection
    } = this.state;
    const currentSectionName = currentSection?.survey_info_section_name || '';
    if(await this.manageMandatoryFields()){
      return;
    }
    this.modifyKey(fieldValues[currentSectionName], currentSectionName, `${currentSectionName} 1`);
    let newStateAccodions: Pick<S, 'openAccordions' | 'fieldValues'>;
    const {[sectionId]: currentAccordion, ...rest} = this.state.openAccordions;
    if (currentAccordion) {
      newStateAccodions = ({
        openAccordions: {
          ...rest,
          [sectionId]: [...currentAccordion, {
            isOpen: true,
            subsectionName: `${this.state.currentSection?.survey_info_section_name} ${this.geNextSectionNumber(currentAccordion)}`,
            subsectionId: null,
          }]
        },
        fieldValues,
      });
    } else {
     newStateAccodions = ({
        openAccordions: {
          ...rest,
          [sectionId]: [{
            isOpen: true,
            subsectionName: `${this.state.currentSection?.survey_info_section_name} 1`,
            subsectionId: null,
          }, {
            isOpen: true,
            subsectionName: `${this.state.currentSection?.survey_info_section_name} 2`,
            subsectionId: null,
          }]
        },
        fieldValues,
      });
    }

    if (!fieldValues[currentSectionName]) {
        newStateAccodions = {
        ...newStateAccodions,
        fieldValues: {
          [currentSectionName]: {
            [currentSectionName]: {}
          },
        }
      }
    }
    let updatedPhotosUploaded: PhotosState = {};
    Object.keys(this.state.photosUpdated).forEach(key => {
      const updatedKey = key === currentSectionName ? `${currentSectionName} 1` : key;
      const updatedPhotos = this.state.photosUpdated[key].map(photo => ({
        ...photo,
        subSectionNew: updatedKey
      }));
      updatedPhotosUploaded[updatedKey] = updatedPhotos;
    });
    this.setState({photosUpdated: updatedPhotosUploaded});
    this.setState(newStateAccodions,
      () => {
        this.handleCustomFormAccordion();
        this.handleAfterAddingNewSection(sectionId);
        this.handleSectionSaveData();
      }
    );
  };

   handleSectionSaveData = async () => {
    const { currentSection, fieldValues, addSimilarResponse, createuser } = this.state;
    const currentSectionName = currentSection?.survey_info_section_name || '';
    const userSurveySections = addSimilarResponse.data.attributes.user_survey_sections;
  
    const sectionsByName = this.getSectionsByName(userSurveySections, currentSectionName);
    const result = this.generateResultObject(currentSectionName, sectionsByName, fieldValues, createuser);
  
    this.saveSectionDataApi = await this.apiCallSurvey({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.addUserApiMethod,
      endPoint: configJSON.addSimilarSectionsApiEndPoint,
      body: result,
    });
  };
  
  getSectionsByName = (userSurveySections: any[], currentSectionName: string) => {
    return userSurveySections.filter((section: { user_survey_section_name: any; }) =>
      section.user_survey_section_name === currentSectionName
    );
  };
  
  generateResultObject = (currentSectionName: string, sectionsByName: any[], fieldValues: { [section: string]: { [subSection: string]: { [field: string]: string | string[]; }; }; }, createuser: CreateSurveyUsers) => {
    const result = {
      'survey_template_id': this.extractFromQueryParams("templateId"),
      'user_survey_id': createuser.data.id,
      "user_survey_sections_attributes": sectionsByName.map((value: { id: string|number; user_survey_sub_sections: any[]; }) => {
        return {
          'id': value.id,
          'user_survey_sub_sections_attributes': value.user_survey_sub_sections.map((subSection: { id: number;user_survey_sub_section_name: any; user_survey_sub_section_details: any[]; }) => {
            return {
              "user_survey_sub_section_name": this.getUserSurveySubSectionName(subSection, currentSectionName),
              "id": subSection.id,
              "user_survey_sub_section_details_attributes": this.getUserSurveySubSectionDetails(subSection, currentSectionName, fieldValues)
            };
          })
        };
      })
    };
    return result;
  };

  getUserSurveySubSectionName = (subSection: { user_survey_sub_section_name: any; }, currentSectionName: any) => {
    return subSection.user_survey_sub_section_name === currentSectionName ? `${subSection.user_survey_sub_section_name} 1` : subSection.user_survey_sub_section_name;
  };
  
  getUserSurveySubSectionDetails = (subSection: { id?: number; user_survey_sub_section_name: any; user_survey_sub_section_details: any; }, currentSectionName: string, fieldValues: { [x: string]: { [subSection: string]: { [field: string]: string | string[]; }; } | { [x: string]: any; }; }) => {
  if (
    fieldValues[currentSectionName] &&
    fieldValues[currentSectionName][subSection.user_survey_sub_section_name]
  ) {
    const currentSubSectionValues = fieldValues[currentSectionName][subSection.user_survey_sub_section_name];
    return subSection.user_survey_sub_section_details.map((item: {dynamic_dropdown_type:string; id:string|number; field_name: string; field_value: any; field_value_multiple: any; field_type: any; field_options: any; photos: any; }) => {
      const fieldValue = currentSubSectionValues[item.field_value];
      return {
        id: item.id,
        field_name: item.field_name,
        field_value: fieldValue,
        field_value_multiple: item.field_value_multiple,
        field_type: item.field_type,
        field_options: item.field_options,
        photos: item.photos
      }});
    } else {
      return [];
    }
  };
  
  private geNextSectionNumber(subSections: {
    subsectionId: string | null;
    subsectionName: string;
    isOpen: boolean;}[]
  ) {
    const lastSubSection = subSections[subSections.length - 1];
    const lastSubSectioNumber = +lastSubSection.subsectionName.split(' ').slice(-1);
    return lastSubSectioNumber + 1;
  }

  checkMandatoryFieldsForAllSection = (template: SurveyAttributes, values: { [x: string]: { [subSection: string]: { [field: string]: string | string[]; }; } | { [x: string]: { [x: string]: any; }; }; }) => {
    for (const section of template.survey_info_section) {
      for (const field of section.survey_info_section_fields) {
        if (field.is_mandatory && !values[section.survey_info_section_name][section.survey_info_section_name][field.field_name]) {
          return false;
        }
      }
    }
    return true;
  }

  handleAllSubmit = async(status: string) => {
    if(await this.manageMandatoryFields()){
      return;
    } else {
      if(this.state.surveyAttributes){
      const allFieldsFilled: boolean = this.checkMandatoryFieldsForAllSection(this.state.surveyAttributes, this.state.fieldValues);
      if(allFieldsFilled){
        this.handleSurveySubmit(status);
      } else {
        this.handleEror("Please fill all mandatory fields");
        return;
      }
    }
  }
  }

  handleSurveySubmit = async (status: string): Promise<void> => {
    if(await this.manageMandatoryFields()){
      return;
    }
    if(status==="Started"){
      this.setState({saveBtnResponse: true,showPopUp:true})
    }else{
    this.setState({saveBtnResponse: false,showPopUp:true})
    }
    const { addSimilarResponse } = this.state;
     
    const updatedValuesArray: UserSurveyDetails[] = [];  
    for (const sections of addSimilarResponse.data.attributes.user_survey_sections) {
      for (const subSection of sections.user_survey_sub_sections) {
        for (const details of subSection.user_survey_sub_section_details) {
          updatedValuesArray.push({
            userSurveySecId: sections.id,
            userSurveySecName: sections.user_survey_section_name,
            userSurveySubSecId: subSection.id,
            userSurveySubSecName: subSection.user_survey_sub_section_name,
            userSurveyDetails: details,
          });
        }
      }
    }
    await this.submitFormDetails(updatedValuesArray, status);
  };
  
  submitFormDetails = async (
    updatedValuesArray: UserSurveyDetails[],
    status: string
  ): Promise<void> => {
    const { fieldValues, addSimilarResponse } = this.state;
  
    const userSurveySectionsAttributes: UserSurveySectionAttributes[] = [];
  
    for (const value of updatedValuesArray) {
      let section = userSurveySectionsAttributes.find(
        (section: { id: number; }) => section.id=== value.userSurveySecId
      );
  
      if (!section) {
        section = {
          id: value.userSurveySecId,
          user_survey_section_name: value.userSurveySecName,
          user_survey_sub_sections_attributes: [],
        };
        userSurveySectionsAttributes.push(section);
      }
  
      let subSection = section.user_survey_sub_sections_attributes.find(
        (sub) => sub.id === value.userSurveySubSecId
      );
  
      if (!subSection) {
        subSection = {
          id: value.userSurveySubSecId,
          user_survey_sub_section_name: value.userSurveySubSecName,
          user_survey_sub_section_details_attributes: [],
        };
        section.user_survey_sub_sections_attributes.push(subSection);
      }
  
      subSection.user_survey_sub_section_details_attributes.push({
        id: value.userSurveyDetails.id,
        field_name: value.userSurveyDetails.field_name,
        field_value: fieldValues[value.userSurveySecName][value.userSurveySubSecName][value.userSurveyDetails.field_name],
        photos: [],
      });
    }
  
    const raw = {
      user_survey_id: addSimilarResponse.data.id,
      survey_name: addSimilarResponse.data.attributes.survey_name,
      status: status,
      user_survey_sections_attributes: userSurveySectionsAttributes,
    };
    this.createSurveyApiCallId = await this.apiCallSurvey({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.patchUserApiMethod,
      endPoint: `${configJSON.patchUserApiEndPoint}/${addSimilarResponse.data.id}`,
      body: raw,
    });
  };

  removeSubSections = async (subSecName: string): Promise<void> => {
    let {addSimilarResponse} = this.state
    let templateId = this.extractFromQueryParams("templateId");
    let surveyId = this.extractFromQueryParams("surveyId");
    let currentSectionId = this.extractFromQueryParams("sectionId");
    let subSections = addSimilarResponse.data.attributes.user_survey_sections.flatMap(section => section.user_survey_sub_sections);
    let foundSubSection = subSections.find(subSection => subSection.user_survey_sub_section_name === subSecName);
    let subSectionID:number;
    if(foundSubSection){
      subSectionID = foundSubSection.id;
      this.deleteSubsectionApiCallId = await this.apiCallSubsectionDelete({
        contentType: configJSON.getUserListApiContentType,
        method: configJSON.deleteSubsectionApiMethod,
        endPoint: `${configJSON.deleteSubsectionApiEndpoint}?survey_template_id=${templateId}&user_survey_id=${surveyId}&user_survey_section_id=${currentSectionId}&user_survey_sub_section_id=${subSectionID}`,
      });
    } else return;
  };

  togleCopyLastFilledData = async(sectionName: string, value: boolean) => {
      this.sendCopyFromLastFilledRequest(sectionName, value);
  }

  /**
   * when we have no subsections subsection key for create survey api is name of the section.
   * when we add a subsection which didn't have any subsections
   * we need to change name of the first subsection from "{section_name}" to "{section_name 1}"
   */
  private handleAfterAddingNewSection = (sectionId: number) => {
    if (this.state.openAccordions[sectionId].length === 2) {
      this.setState((prevState) => {
        const sectionName = prevState.currentSection ? prevState.currentSection.survey_info_section_name : '';
        const { [sectionName]: old, ...rest } = this.state.fieldValues[
          sectionName
        ];
        return {
          fieldValues: {
            ...prevState.fieldValues,
            [sectionName]: {
              ...rest,
              [`${sectionName} 1`]: {
                ...old,
              },
            },
          },
        }
      });
    } else {
      this.setState((prevState) => {
        const sectionName = prevState.currentSection ? prevState.currentSection.survey_info_section_name : '';
        const { [sectionName]: old, ...rest } = this.state.fieldValues[
          sectionName
        ];
        return {
          fieldValues: {
            ...prevState.fieldValues,
            [sectionName]: {
              ...rest,
              [`${this.state.openAccordions[sectionId][this.state.openAccordions[sectionId].length - 1].subsectionName}`]: {
                ...old,
              },
            },
          },
        }
      });
    }
  };

  get openAccordionsOpen() {
    const currentAccordion = this.state.openAccordions[this.state.currentSectionId];
    return !!currentAccordion?.length && currentAccordion.length > 1;
  }

  get openAccordions() {
    const currentAccordion = this.state.openAccordions[this.state.currentSectionId];
    return currentAccordion ?? [];
  }

  get openAccordionsNew () {
    const {openAccordions, currentSectionId} = this.state;
    return openAccordions[currentSectionId];
  }

  private handleCustomFormAccordion = () => {
    this.setState({
      openAccordionsOpen: true
    });
  };

  handlePopUpClose = () => {
    this.setState({
      showPopUp: false,
      error: '',
    })
  }

  resetSelectedGlobalSearchOption = () => {
    this.setState({selectedGlobalSearchOption: null});
  }

  handleGlobalSearch = async (option: string) => {
    const selectedGlobalSearchOption = option.split(` (in `)[0];
    this.setState({selectedGlobalSearchOption});
    const selectedField=option.split(` (in `)[1].trim() || ''
    this.setState({option:selectedField.replace(')','')});
    const searchParams = new URLSearchParams({
      searched_field: option,
      survey_template_id: this.extractFromQueryParams("templateId"),
      user_survey_id: this.extractFromQueryParams("surveyId"),
    });
    const paramsString = searchParams.toString();
    const url = `/bx_block_survey/survey_advanced_search/search_query_survey_details?${paramsString}`;
    const token = await storage.get('auth-token');
    sendRequest(
      url,
      "GET",
      (id) => this.globalSearchMessageId = id,
      null,
      { token }
    );
  }


  private handleSuccessGetTemplateResponse(survey: SurveyResponse) {
    const attributes = survey.survey_template_details.data.attributes;
    let customArray = attributes?.survey_info_section;
    let array: PhotosNew[] =[];
    customArray.forEach((item:SurveyInfoSection)=>{
             item["newMedia"]=array;
    })
    customArray.forEach((val) => {
      const matchingSection = this.state.listOfSectionsSubsections.find(
        (section: any) =>
          section.survey_section_name === val.survey_info_section_name
      );
  
      if (matchingSection) {
        val.id = matchingSection.id;
      } 
    });
  
    const nearestId = attributes.survey_info_section[0].id;
    const currentId = Number(this.extractFromQueryParams("sectionId"));
    const currentSectionId = currentId || nearestId;
    const currentSection = attributes.survey_info_section.find(
      (section) => section.id === currentSectionId
    );

    this.setState({
      surveyAttributes: attributes,
      currentSectionId,
      sections: customArray,
      currentFields: currentSection?.survey_info_section_fields || [],
      currentSection,
    });
  }
  



  handleSectionSearchBar = (e:any)=>{
    this.setState({
      searchSpecificSec:e.target.value,
    },()=>{
      this.filterSpecificSections()
      this.navigationSearchWithinSection()
      this.getListOfSectionSubSection()
    }
    )
  }

  filterSpecificSections = () => {
    const { searchSpecificSec } = this.state;
    const filteredSections = this.customFormLinks.filter((section: any) =>
      section.survey_info_section_name
        .toLowerCase()
        .includes(searchSpecificSec.toLowerCase())
    );

    this.setState({ specificSecSearchesData:filteredSections });
  };

  private get currentSectionIndex() {
    return this.state.sections.findIndex(
      (section) => section.id === this.state.currentSectionId
    );
  }

  private handleResponse = (message: Message, onSuccess: (success: any) => void, onError: (errors: any[]) => void) => {
    const success = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (!success) {
      this.setState({ error: "An unexpected error occured", showPopUp: true});
      return;
    }
    if (success.errors) {
      onError(success.errors);
      return;
    }
    onSuccess(success);
  }

  getSurveyFieldValues = async(createUserResponseParse: CreateSurveyUsers) => {
    const currentSectionId = Number(this.extractFromQueryParams("sectionId"))
    let newFieldValues = Object.assign({}, ...createUserResponseParse.data.attributes.user_survey_sections.map((item: { user_survey_section_name: any; user_survey_sub_sections: any[]; })=>{
      return {
              [item.user_survey_section_name]:Object.assign({}, ...item.user_survey_sub_sections.map((val: { user_survey_sub_section_name: any; user_survey_sub_section_details: any[]; })=>{
                  return {
                      [val.user_survey_sub_section_name]: Object.assign({}, ...val.user_survey_sub_section_details.map((detail: {
                        field_value_multiple: []; field_type: string; field_name: string; field_value: string; 
                      })=>{
                      return{
                          [detail.field_name]: detail.field_type == "checkbox" ? detail.field_value_multiple : detail.field_value || ''
                      }
                  }))
              }
              }))
      }
  }))
  let mandatoryFieldValues = Object.assign({}, ...createUserResponseParse.data.attributes.user_survey_sections.map((item: { user_survey_section_name: any; user_survey_sub_sections: any[]; })=>{
    return {
            [item.user_survey_section_name]:Object.assign({}, ...item.user_survey_sub_sections.map((val: { user_survey_sub_section_name: any; user_survey_sub_section_details: any[]; })=>{
                return {
                    [val.user_survey_sub_section_name]: Object.assign({}, ...val.user_survey_sub_section_details.map((detail: { field_name: any; })=>{
                    return{
                        [detail.field_name]: false
                      }
                  }))
              }
              }))
      }
  }))
  const userSurveySectionFields = createUserResponseParse.data.attributes.user_survey_sections
    this.setState({
      userSurveyId: String(createUserResponseParse.data.id),
      userSurveySectionFields,
      currentSectionId,
      addSimilarResponse: createUserResponseParse,
      fieldValues: newFieldValues,
      isMandatoryError: mandatoryFieldValues,
      createuser: createUserResponseParse,
    })
  }

  determineIsOpen = (subSection: string | any[]) => {
    return subSection.length > 1;
  }

  getAccordianData = (addSimilarResponse: CreateSurveyUsers) => {
    let openAccordions: SubsectionsAccordion = {};
    addSimilarResponse.data.attributes.user_survey_sections.forEach(section => {
      const isOpen = this.determineIsOpen(section.user_survey_sub_sections);
      section.user_survey_sub_sections.forEach(subSection => {
            if (isOpen) {
                if (!openAccordions[section.id]) {
                    openAccordions[section.id] = [];
                }
                openAccordions[section.id].push({
                  subsectionId: subSection.id.toString(),
                  subsectionName: subSection.user_survey_sub_section_name,
                  isOpen:this.state.option.toLocaleLowerCase()===subSection.user_survey_sub_section_name.toLocaleLowerCase()
                });
            }
        });
    });
  this.setState({openAccordions})
  }

  getUploadedFilesData = (createUserResponseParse: CreateSurveyUsers, currentFields: any[]) => {
    let photosUpdated: PhotosState = {};
    let uploadedImages: any ={};
    let uploadedVideos: any = {};
    const processMedia = (media: Photos[], detail: UserSurveySubSectionDetails, subSection: UserSurveySubSections, type: string) => {
        media.forEach((item: Photos) => {
            const field = currentFields.find(field => field.field_name === detail.field_name);
            if (field) {
                const fieldId = field.id;
                const media_id = item.media_id;
                const subSectionNew = subSection.user_survey_sub_section_name;
                if (!photosUpdated[subSection.user_survey_sub_section_name]) {
                    photosUpdated[subSection.user_survey_sub_section_name] = [];
                }
                photosUpdated[subSection.user_survey_sub_section_name].push({ ...item, fieldId, subSectionNew, media_id });
                if (!uploadedImages[subSection.user_survey_sub_section_name]) {
                  uploadedImages[subSection.user_survey_sub_section_name] = [];
                }
                if (!uploadedVideos[subSection.user_survey_sub_section_name]) {
                    uploadedVideos[subSection.user_survey_sub_section_name] = [];
                }
                if (type === 'photos') {
                    uploadedImages[subSection.user_survey_sub_section_name].push(item);
                } else {
                    uploadedVideos[subSection.user_survey_sub_section_name].push(item);
                }
            }
        });
    };
    createUserResponseParse.data.attributes.user_survey_sections.forEach(section => {
        section.user_survey_sub_sections.forEach(subSection => {
            subSection.user_survey_sub_section_details.forEach(detail => {
                if (detail.photos && detail.photos.length > 0) {
                    processMedia(detail.photos, detail, subSection, 'photos');
                }
                if (detail.videos && detail.videos.length > 0) {
                    processMedia(detail.videos, detail, subSection, 'videos');
                }
            });
        });
    });
    this.setState({ photosUpdated });
}

  getSurveyData = async (createUserResponseParse: CreateSurveyUsers) => {
    this.getAccordianData(createUserResponseParse);
    this.getUploadedFilesData(createUserResponseParse, this.state.currentFields);
    await this.getSurveyFieldValues(createUserResponseParse);
    await this.navigationSearchWithinSection();
    await this.getListOfSectionSubSection()
    await this.getDynamicOptionsApiCall();
  }

  handleAutoSaveDetails = async (): Promise<void> => {
    const { createuser, addSimilarResponse, fieldValues } = this.state;
    const templateId = this.props.navigation.getParam("templateId");
    let raw = {
        user_survey_id: createuser.data.id,
        survey_template_id: templateId,
        survey_name: createuser.data.attributes.survey_name,
        user_survey_sections_attributes: addSimilarResponse.data.attributes.user_survey_sections.map((detail) => ({
            id: detail.id,
            user_survey_section_name: detail?.user_survey_section_name,
            user_survey_sub_sections_attributes: detail.user_survey_sub_sections.map((data)=>({
              id: data.id,
              user_survey_sub_section_name: data?.user_survey_sub_section_name,
              user_survey_sub_section_details_attributes: data.user_survey_sub_section_details.map((value)=>({
                      id: value.id,
                      field_name: value.field_name,
                      field_value: value.field_type === "checkbox" ? '' : fieldValues?.[detail?.user_survey_section_name]?.[data?.user_survey_sub_section_name]?.[value.field_name],
                      field_options: value.field_options,
                      field_value_multiple: value.field_type === "checkbox" ? fieldValues?.[detail?.user_survey_section_name]?.[data?.user_survey_sub_section_name]?.[value.field_name]: [],
            })
            )
            }))
        }))
    };
    this.autoSaveApiCallId = await this.apiCallSurvey({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.addUserApiMethod,
      endPoint: configJSON.autoSaveUserApiEndPoint,
      body: raw,
    });
  };

  copyAllFilesToNewSection = async (sectionName: string) => {
    const { addSimilarResponse } = this.state;
    const sectionData = addSimilarResponse?.data?.attributes.user_survey_sections.find(
      (section) => section.user_survey_section_name === sectionName
    );
    if (!sectionData || sectionData.user_survey_sub_sections.length < 2) {
      return;
    }

    const sortedSubSections = sectionData.user_survey_sub_sections.slice().sort((a, b) => {
      return a.id - b.id;
    });
    const secondLastSubSection = sortedSubSections[sortedSubSections.length - 2];
    const lastSubSection = sortedSubSections[sortedSubSections.length - 1];

    const result = this.extractMediaData(secondLastSubSection, lastSubSection);

    const token = storage.get("auth-token");
    result.forEach((item) => {
      sendRequest(
        `bx_block_survey/users_survey/copy_uploaded_files`,
        "POST",
        (id) => this.copyMediaDataId = id,
        item,
        { token }
      );
    });
  }

  extractMediaData = (secondLastSubSection: any, lastSubSection: any) => {
      const result: { user_survey_sub_section_detail_id: string | number; attached_photos: any[]; attached_videos: any[]; }[] = [];
      secondLastSubSection.user_survey_sub_section_details.forEach((field: any) => {
        if ((field.photos && field.photos.length > 0) || (field.videos && field.videos.length > 0)) {
          const fieldName = field.field_name;
          const user_survey_sub_section_detail_id = this.extractFieldId(lastSubSection.user_survey_sub_section_details, fieldName);
          const { attached_photos, attached_videos } = this.extractMediaIds(secondLastSubSection.user_survey_sub_section_details, fieldName);
          result.push({
            user_survey_sub_section_detail_id,
            attached_photos,
            attached_videos
          });
        }
      });
      return result;
  }

  extractMediaIds = (details: any[], fieldName: string) => {
      let attached_photos: any[] = [];
      let attached_videos: any[] = [];
      details.forEach((field) => {
        if (field.field_name === fieldName) {
          if (field.photos && field.photos.length > 0) {
            attached_photos = field.photos.map((photo: { description: string; id: number; }) => ({ id: photo.id, description: photo.description }));
          }
          if (field.videos && field.videos.length > 0) {
            attached_videos = field.videos.map((video: { description: string; id: number; }) => ({ id: video.id, description: video.description }));
          }
        }
      });
      return { attached_photos, attached_videos };
  }

  extractFieldId = (details: any[], fieldName: string) => {
    const reversedDetails = details.reduceRight((acc, curr) => {
      acc.push(curr);
      return acc;
  }, []);
  
    const matchingField = reversedDetails.find((field: { field_name: string; }) => field.field_name === fieldName);
    return matchingField ? matchingField.id : null;
  }


  getSubSectionName = (response: { data: { attributes: { user_survey_sub_section_detail_id: number; }; }; }) => {
    const { addSimilarResponse } = this.state;
    let subSectionName;
    addSimilarResponse.data.attributes.user_survey_sections.forEach((section) => {
        section.user_survey_sub_sections.forEach((subSection) => {
            subSection.user_survey_sub_section_details.forEach((detail) => {
                if (detail.id === response.data.attributes.user_survey_sub_section_detail_id) {
                    subSectionName = subSection.user_survey_sub_section_name;
                }
            });
        });
    });
    return subSectionName;
}


  manageCopyMediaStates = (response: any) => {
    const { photosUpdated, addSimilarResponse, currentSection } = this.state;
    if (!addSimilarResponse || !currentSection) {
        console.error("addSimilarResponse or currentSection is undefined.");
        return;
    }
    let subSectionName = this.getSubSectionName(response);
    let sectionName = currentSection.survey_info_section_name;
    if(subSectionName){
    let addSimilarSection = addSimilarResponse.data?.attributes?.user_survey_sections?.find(section => section.user_survey_section_name === sectionName)?.user_survey_sub_sections;
    if (!addSimilarSection) {
        console.error("addSimilarSection is undefined.");
        return;
    }
    addSimilarSection.sort((a, b) => a.id - b.id);
    let previousSubSection = "";
    for (let i = 0; i < addSimilarSection.length; i++) {
        if (addSimilarSection[i].user_survey_sub_section_name === subSectionName) {
            previousSubSection = addSimilarSection[i - 1]?.user_survey_sub_section_name;
            break;
        }
    }
    let newPhotosUpdated = {
        [subSectionName]: photosUpdated[previousSubSection]?.map((photo: any) => ({
            ...photo,
            media_id: response.data.attributes.id,
            subSectionNew: subSectionName
        })) ?? []
    };
    const updatedPhotos: any = {};
    for (const key in newPhotosUpdated) {
        updatedPhotos[key] = newPhotosUpdated[key];
    }
    this.setState((prevState) => ({
      photosUpdated: {
          ...prevState.photosUpdated,
          ...updatedPhotos
      }
  }));
  }
  }

  sendCopyFromLastFilledRequest = async (sectionName: string, value:boolean) => {
    const targetSubSectionId = await this.getTargetSubSectionId(sectionName);
    if (value) {
      await storage.set("copyCheckBox", JSON.stringify(true));
        this.sendCopyRequest(targetSubSectionId);
    } else {
      await storage.set("copyCheckBox", JSON.stringify(false));
        this.resetFieldValues();
    }
  }

  getTargetSubSectionId = (sectionName: string) => {
    const { addSimilarResponse } = this.state;
    const subSectionId = addSimilarResponse.data.attributes.user_survey_sections || [];
    for (const section of subSectionId) {
        if (section.user_survey_section_name === sectionName) {
            const subSections = section.user_survey_sub_sections || [];
            const sortedSubSections = [...subSections].sort((a, b) => b.id - a.id);
            const secondLastSubSection = sortedSubSections[1];
            if (secondLastSubSection) {
                return secondLastSubSection.id;
            }
        }
    }
    return null;
  }

  sendCopyRequest = (targetSubSectionId: string | number | null) => {
    const surveyId = this.extractFromQueryParams("surveyId");
    const currentSectionId = this.extractFromQueryParams("sectionId");
    const token = storage.get("auth-token");

    sendRequest(
        `bx_block_survey/users_survey/copy_data_from_last_sub_section?user_survey_id=${surveyId}&user_survey_section_id=${currentSectionId}&user_survey_sub_section_id=${targetSubSectionId}`,
        "GET",
        (id) => this.copyFromLastFilledId = id,
        null,
        { token }
    );
  }

  getLastSubsectionName = () => {
    const {addSimilarResponse} = this.state;
    const sectionName = this.state.currentSection?.survey_info_section_name || '';
    const sections = addSimilarResponse.data.attributes.user_survey_sections;
    for (const section of sections) {
        if (section.user_survey_section_name === sectionName) {
            const subSections = section.user_survey_sub_sections;
            if (subSections.length > 0) {
                subSections.sort((a, b) => a.user_survey_sub_section_name.localeCompare(b.user_survey_sub_section_name));
                return subSections[subSections.length - 1].user_survey_sub_section_name;
            }
        }
    }
    return '';
  }

  resetFieldValues = () => {
    const { fieldValues, openAccordions, currentSectionId, photosUpdated } = this.state;
    const sectionName = this.state.currentSection?.survey_info_section_name || '';
    const targetSubsection = this.getLastSubsectionName();
    const sectionPhotos = photosUpdated[targetSubsection];
    if(sectionPhotos){
      sectionPhotos.forEach((file: any, index: number) => {
      this.deleteCopiedUploadedFile(file, targetSubsection, index)
      let isFormat = file.content_type.split("/")[0];
      let itemID = file.id;
      let media_id = file.media_id;
      this.setState({deleteMediaItemIndex:index});
      let isEndpoint = isFormat == "image" ? `photo_id=${itemID}`:`video_id=${itemID}`;
      this.deleteCopiedFileAPICall(media_id, isEndpoint)
        });
      }
      const updateLastField = this.state.fieldValues[sectionName] && Object.keys(this.state.fieldValues[sectionName]).pop()
    this.setState({
        fieldValues: {
            ...fieldValues,
            [sectionName]: {
                ...fieldValues[sectionName],
                [this.checkEmpty(openAccordions) ? `${sectionName}` : `${updateLastField}`]: {
                    ...this.state.currentSection?.survey_info_section_fields.reduce((prev, curr) => ({
                        ...prev,
                        [curr.field_name]: curr.field_type === "checkbox" ? [] : '',
                    }), {})
                }
            }
        }
    });
    this.handleAutoSaveDetails();
  }

  private handleCopyLastFilledSuccessResponse = (response: CopyFromLastFilledResponse) => {
    const {fieldValues, openAccordions,currentSectionId} = this.state;
    const sectionName = this.state.currentSection?.survey_info_section_name ?? "";
    const updateLastField = this.state.fieldValues[sectionName] && Object.keys(this.state.fieldValues[sectionName]).pop()
    this.setState({
      showPopUp: false,
      error: '',
      fieldValues: {
        ...fieldValues,
        [sectionName]: {
          ...fieldValues[sectionName],
          [this.checkEmpty(openAccordions) ? `${sectionName}`:`${updateLastField}`]: {
            ...response.user_survey_sub_section_details.reduce((prev, curr) => ({
              ...prev,
              [curr.field_name]:  curr.field_type === "checkbox" ? curr.field_value_multiple : curr.field_value,
            }), {})
          }
        }
      }
    })
    this.handleAutoSaveDetails();
  }
  

  handleDeleteSubsectionPopup = (subSecName:string)=>{
    this.setState({
      subSecName,
      deleteSubSecPopup:true,
    })
  }

  handleCloseDeleteSubsecPopup = ()=>{
    this.setState({
      deleteSubSecPopup:false,
    })
  }

  removeByKey = (obj: { [x: string]: any; }, keyToRemove: string) => {
    Object.keys(obj).forEach(key => {
      if (key === keyToRemove) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        this.removeByKey(obj[key], keyToRemove);
      }
    });
  }
  
  renameToSectionName = (obj: { [x: string]: any; }, sectionName: string) => {
    const remainingKeys = Object.keys(obj);
    if (remainingKeys.length === 1) {
      const remainingKey = remainingKeys[0];
      obj[sectionName] = obj[remainingKey];
      delete obj[remainingKey];
    }
  }

  removeSubSectionFromList = (sectionId: number, subsectionName: string, sections: any[]) => {
    return sections.map((section: { id: any; user_survey_sub_sections: any[]; }) => {
      if (section.id === sectionId) {
        section.user_survey_sub_sections = section.user_survey_sub_sections.filter((subsection: { survey_sub_section_name: any; }) => {
          return subsection.survey_sub_section_name !== subsectionName;
        });
        if (section.user_survey_sub_sections.length === 1) {
          section.user_survey_sub_sections = [];
        }
      }
      return section;
    });
  }

  removeSubsectionOrObject = (subSecName: string): any => {
    const updatedOpenAccordions = { ...this.state.openAccordions };
    const currentSectionId = this.state.currentSectionId;

    if (updatedOpenAccordions[currentSectionId]?.length === 2) {
      this.setState({subSecName: this.state.currentSection?.survey_info_section_name || ''})
      delete updatedOpenAccordions[currentSectionId];
    } else {
      updatedOpenAccordions[currentSectionId] = updatedOpenAccordions[currentSectionId]?.filter(
        accordion => accordion.subsectionName !== subSecName
      );
    }
  this.setState({ openAccordions: updatedOpenAccordions }); 
  }

  getNewKey = (subSecName: string) =>{
    return /\s\d$/.test(subSecName) ? subSecName.replace(/\s\d$/, '') : subSecName;
  }

handleDeleteUpdatedPhotos =  async(subSecName:string)=>{
  
  let newPhotoState = { ...this.state.photosUpdated }

  if(this.openAccordionsNew?.length == 2){
    delete newPhotoState[subSecName]
    if (Object.keys(newPhotoState).length == 1){

  let oldKey = Object.keys(newPhotoState)[0]
    let currentvalue = newPhotoState[oldKey]
   let newKey = this.getNewKey(subSecName)
    newPhotoState[newKey] = currentvalue;

    if (newKey !== oldKey) {
      delete newPhotoState[oldKey]
    }

     }
    this.setState({
      photosUpdated: newPhotoState
    })
     
  }else{
   delete newPhotoState[subSecName]
    this.setState({
      photosUpdated: newPhotoState
    })
  }
}

 handleDeleteElementAccordion = async()=>{
   
   const { subSecName, addSimilarResponse, currentSectionId, fieldValues, listOfSectionsSubsections } = this.state;
   this.handleDeleteUpdatedPhotos(subSecName)

  let newSubSectionList = this.removeSubSectionFromList(currentSectionId, subSecName, listOfSectionsSubsections)
  await this.removeSubSections(subSecName);
  Object.keys(fieldValues).forEach(section => {
    this.removeByKey(fieldValues[section], subSecName);
    this.renameToSectionName(fieldValues[section], section);
  });
  const newUserSurveySections = addSimilarResponse.data.attributes.user_survey_sections.map(section => {
    if (section.id === currentSectionId) {
      const subsectionsCount = section.user_survey_sub_sections.length;
      if (subsectionsCount > 2) {
        section.user_survey_sub_sections.forEach(subsection => {
          subsection.user_survey_sub_section_details.forEach(detail => {
            detail.field_value = null;
            detail.photos = [];
          });
        });
      } else if (subsectionsCount === 2) {
        section.user_survey_sub_sections = section.user_survey_sub_sections.filter(subsection => {
          if (subsection.user_survey_sub_section_name === subSecName) {
            return false;
          } else {
            subsection.user_survey_sub_section_name = section.user_survey_section_name;
            return true;
          }
        });
      }
    }
    return section;
  });
  addSimilarResponse.data.attributes.user_survey_sections = newUserSurveySections;
  storage.set("createUserResponse",JSON.stringify(addSimilarResponse));
  this.setState({
    deleteSubSecPopup: false,
    addSimilarResponse,
    fieldValues,
    listOfSectionsSubsections: newSubSectionList
  }, ()=> {
    this.handleAutoSaveDetails();
  });
  await this.removeSubsectionOrObject(subSecName)
 }

  isImage = (file: File) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif','.tiff', '.bmp', '.heic'];
    const extension = file.name.toLowerCase();
    return imageExtensions.some(ext => extension.endsWith(ext));
  };

  isVideo = (file: File) => {
    const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv','.mpeg','quicktime', 'WebM', '.wmv'];
    const extension = file.name.toLowerCase();
    return videoExtensions.some(ext => extension.endsWith(ext));
  };

  handleBulkUploadModal = async (eventFields: React.MouseEvent, uniqueId: number, subSection: string) => {
    eventFields.stopPropagation(); 
    this.setState({
      openUploadModal: true,
      fileLimitExceeded: false,
      uploadValidationError: "",
      progressBar: 0,
      startDownloading: false,
      uploadSuccessfully: false,
      totalFiles: 0,
      subSectionNew: subSection
    });
    const templateId = this.extractFromQueryParams("templateId");
    const sectionId = this.extractFromQueryParams("sectionId");
    const { createuser } = this.state;
    const filteredSections = createuser.data.attributes.user_survey_sections.filter((section: UserSurveySections) => section.id);
    let data = filteredSections.filter((item)=>item.id == sectionId)
    let newData = data.length > 0 ? data[0].user_survey_sub_sections[0].id : null;
    const filterItemSubSections = this.state.addSimilarResponse.data.attributes.user_survey_sections.filter((sections: UserSurveySections) => sections.id);
    let subSectionData = filterItemSubSections.filter((item) => item.id == sectionId);
    let subSectionId = null;
    if (subSectionData.length > 0) {
        let subSectionDetails = subSectionData[0].user_survey_sub_sections.filter((item: UserSurveySubSections) => item.user_survey_sub_section_name === subSection);
        subSectionId = subSectionDetails.length > 0 ? subSectionDetails[0].id : null;
    }
    const subSectionToSend = subSectionId ? subSectionId : newData;

    this.getFieldDetailApiCallId = await this.apiCallSurvey({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.getUserListApiMethod,
      endPoint: `bx_block_survey/users_survey/template_section_field_user_survey_mapping?survey_template_id=${templateId}&user_survey_id=${this.state.userSurveyId}&user_survey_section_id=${sectionId}&user_survey_sub_section_id=${subSectionToSend}&template_field_id=${uniqueId}`
    });
};
  
  handleCloseBulkUploadModal =()=>{
    if(this.state.uploadingLoader || !this.state.uploadProgress.every(value => value === 100)) return;
   this.setState({
    uniqueId: null,
    openUploadModal: false, 
    uploadValidationError: "",
    uploadedImages: {},
    uploadedVideos: {},
    selectedImages: {},
    selectedVideos: {},
    progressBar: 0,
    limitValidationError: "",
    limitVideoValidationError:'',
    fileLimitExceeded: false,
    startDownloading: false,
    uploadSuccessfully: false,
    totalFiles: 0 ,
    ispermissionGranted: false,
    isPermissionModalOpen: false,
    isCameraModalOpen: false,
    isFileContainerOpen: true,
    isGalleryAccessAllowed: true
   });
  }
 
  handleUploadDocuments = async () => {
    const {subSectionNew} = this.state;
    const images:any = Object.values(this.state.uploadedImages[subSectionNew]).flat();
    const videos:any = Object.values(this.state.uploadedVideos[subSectionNew]).flat();

    const totalImagesSize = images.reduce((acc: any, file: { size: any; }) => acc + file.size, 0);
    const totalVideosSize = videos.reduce((acc: any, file: { size: any; }) => acc + file.size, 0);
    const totalSize = totalImagesSize + totalVideosSize;
    let fieldId = this.state.fieldDetails?.data?.survey_field_detail?.id;
    const formData = new FormData();
    formData.append("user_survey_media[user_survey_sub_section_detail_id]", String(fieldId || ""));
    
    const selectedImageFile: CustomFile[] = this.state.selectedImages[subSectionNew].map((file) => ({
      file: file
    }));
    const selectedVideoFile: CustomFile[] = this.state.selectedVideos[subSectionNew].map((file) => ({
        file: file
    }));
    
    for (const { file } of selectedImageFile) {
      formData.append("user_survey_media[photos][]", file);
    }
    for (const { file } of selectedVideoFile) {
      formData.append("user_survey_media[medias][]", file);
    }
    const imagesWithStartTime: CustomFile[] = images.map((file: any) => ({
        file: file
    }));
    const videosWithStartTime: CustomFile[] = videos.map((file: any) => ({
        file: file
    }));
    for (const { file } of imagesWithStartTime) {
      const index = imagesWithStartTime.findIndex(item => item.file === file);
      this.uploadFiles(index, totalSize);
    }
  for (const { file } of videosWithStartTime) {
      const index = videosWithStartTime.findIndex(item => item.file === file);
      this.uploadFiles(index, totalSize);
    } 
    this.setState({
      isUploadingTrue: true,
      formData
    }, ()=>{
      this.uploadFileApiCall();
    });
  };

  uploadFileApiCall = async() =>{
    this.setState({ uploadingLoader: true })
    this.bulkUploadingApiCallId = await this.apiCallSurvey({
      method: configJSON.addUserApiMethod,
      endPoint: configJSON.bulkUploadingApiEndPoint,
      body: this.state.formData,
      type: "formData"
    });
  };
  
  uploadFiles = (index: number, totalSize: number) => {
    let progress = 0;
    const startTime = performance.now(); 
    const interval = setInterval(() => {
        const elapsedTime = performance.now() - startTime;
        progress = Math.min(100, (elapsedTime / 1000) * (totalSize / 10));
        this.updateProgress(index, progress, totalSize);
        if (progress >= 100) {
            clearInterval(interval)
        }
    }, 300);
  };

  updateProgressNew = () => {
    this.setState({
      startDownloading: true
    });
  };

   updateProgress = (index: number, progress: number, totalSize: number) => {
    const endTime = performance.now();
    const startTime = this.state.startTime;
    const elapsedTime = endTime - startTime;
    const bytesUploaded = totalSize * progress / 100;
    const startBytesUploaded = 0;
    const bytesTransferred = bytesUploaded - startBytesUploaded;
    const uploadSpeedInKBps = bytesTransferred / (elapsedTime / 1000);
    const uploadSpeedInMBps = uploadSpeedInKBps / 1024;
    let speedUnit: string;
    let speedValue: string;
    if (uploadSpeedInMBps >= 1) {
      speedUnit = 'MB/Sec';
      speedValue = uploadSpeedInMBps.toFixed(2);
    } else {
      speedUnit = 'KB/Sec';
      speedValue = uploadSpeedInKBps.toFixed(2);
    }
    this.setState((prevState) => {
    const uploadProgress = [...prevState.uploadProgress];
    const uploadSpeed = [...prevState.uploadSpeed];
    uploadProgress[index] = progress;
    uploadSpeed[index] = `${speedValue} ${speedUnit}`;
    return { uploadProgress, uploadSpeed };
     },()=>{
     this.updateProgressNew();
     });
    if (progress === 100) {
     this.setState({ uploadSuccessfully: true });
    }
  };

  handleErrorPopup = (errorState: {}) => {
    setTimeout(() => this.setState(errorState), 5000);
  }

  removeFile = (index:number) => {
    const { uploadedImages, uploadedVideos } = this.state;
    let removedFile: any;
    const allImages = Object.values(uploadedImages).flat();
    const allVideos = Object.values(uploadedVideos).flat();
     if (index < allImages.length) {
      removedFile = allImages[index];
      const updatedImages = this.removeFileAtIndex(uploadedImages, index);
    this.setState({
        uploadedImages: updatedImages,
        limitValidationError: `File ${removedFile.name} removed from pictures.`,
      }, () => {
        this.handleErrorPopup({ limitValidationError: '' });
      });
    } else {
      const adjustedIndex = index - allImages.length;
      removedFile = allVideos[adjustedIndex];
      const updatedVideos = this.removeFileAtIndex(uploadedVideos, adjustedIndex);
      this.setState({
        uploadedVideos: updatedVideos,
        limitVideoValidationError: `File ${removedFile.name} removed from videos.`,
    }, () => {
      this.handleErrorPopup({ limitVideoValidationError: '' });
    });
    }
  };

  removeFileAtIndex = (fileObject: { [key: string]: File[] }, index: number) => {
  const updatedFiles = { ...fileObject };
  const keys = Object.keys(updatedFiles);
  for (const key of keys) {
      const files = updatedFiles[key];
      if (index < files.length) {
          updatedFiles[key] = files.filter((_, idx) => idx !== index);
          break;
      } else {
          index -= files.length;
      }
  }
  return updatedFiles; 
  };

  updateFieldOptions = (field_name: string, field_type: string | null) =>{
    const {dynamicOptions, addSimilarResponse } = this.state;
    const optionsKey = field_type === "Floor" ? "survey_associated_floors" : "survey_associated_buildings";
    const newOptions = dynamicOptions.data[optionsKey];
    addSimilarResponse.data.attributes.user_survey_sections.forEach(section => {
        section.user_survey_sub_sections.forEach(subSection => {
            subSection.user_survey_sub_section_details.forEach(detail => {
                if (detail.field_name === field_name) {
                    detail.field_options = newOptions;
                }
            });
        });
    });
    this.setState({addSimilarResponse})
  }

  handleBlurFields=()=>{
    this.handleAutoSaveDetails();
  }

  handleDescriptionChange = async( fileId: Photos, value: string) => {
    let isFormat = fileId.content_type.split("/")[0];
    let isEndpoint = isFormat === "image" ? 'photo_id' : 'video_id';
    let postData: {
      user_survey_media_id: number;
      description: string;
      [key: string]: string | number;
  } = {
        "user_survey_media_id": fileId.media_id,
        "description": value
    }
    postData[isEndpoint] = fileId.id;
    this.handleAddDescription(postData, fileId.subSectionNew)
  }

  deleteUploadedFile = async (fileId: PhotosNew, subSectionNew: string,fileUploadindex:number) => {
    let isFileFormat = fileId.content_type.split("/")[0];
    if(isFileFormat === "image"){
      this.setState({
        fileFormat: "image",
        deleteFileConfirmationPopup: true,
        fileId,
        fileUploadindex: fileUploadindex,
        subSectionNew
      });
    }else{
      this.setState({
        fileFormat: "video",
        deleteFileConfirmationPopup: true,
        fileId,
        fileUploadindex: fileUploadindex,
        subSectionNew
      });
    }
  };

  deleteCopiedUploadedFile = async (fileId: PhotosNew, subSectionNew: string,fileUploadindex:number) => {
    let isFileFormat = fileId.content_type.split("/")[0];
    if(isFileFormat === "image"){
      this.setState({
        fileFormat: "image",
        fileId,
        fileUploadindex: fileUploadindex,
        subSectionNew
      });
    }else{
      this.setState({
        fileFormat: "video",
        fileId,
        fileUploadindex: fileUploadindex,
        subSectionNew
      });
    }
  };

  handleDeleteFilePopup = async() =>{
    let isFormat = this.state.fileId.content_type.split("/")[0];
    let itemID = this.state.fileId.id;
    let media_id = this.state.fileId.media_id;
    this.setState({deleteMediaItemIndex:this.state.fileUploadindex});
    let isEndpoint = isFormat == "image" ? `photo_id=${itemID}`:`video_id=${itemID}`;
    this.deleteFileUploadingApiCallId = await this.apiCallSurvey({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `bx_block_survey/user_survey_media/destroy_media?user_survey_media_id=${media_id}&${isEndpoint}`
    });
  };

  deleteCopiedFileAPICall = async(media_id: number, isEndpoint: string) =>{
    this.deleteCopyFileUploadingApiCallId = await this.apiCallSurvey({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `bx_block_survey/user_survey_media/destroy_media?user_survey_media_id=${media_id}&${isEndpoint}`
    });
  }

  handleCloseDeleteFilePopup =() =>{
    this.setState({
      deleteFileConfirmationPopup: false,
      fileFormat: "",
      fileId: {
        id: 0,
        filename: "",
        content_type: "",
        created_at: "",
        byte_size: 0,
        url: "",
        media_id: 0,
        subSectionNew: "",
        description: '',
        fieldId: 0
      },
      fileUploadindex: 0,
      deleteMediaItemIndex: 0
    });
  };

  handleDeleteResponseSuccess = (response: DeleteFileResponse, fromCopy: boolean) => {
    let message = response.data.message;
    const updatedPhotos = { ...this.state.photosUpdated };
    let deleteIndexNumber = this.state.deleteMediaItemIndex;
    if(message){
        const currentSubsection = this.state.subSectionNew;
        if(!fromCopy){
          updatedPhotos[currentSubsection].splice(deleteIndexNumber, 1);
          this.setState({ photosUpdated: updatedPhotos });
          this.handleCloseDeleteFilePopup();
      } else {
        if (updatedPhotos[currentSubsection]) {
          delete updatedPhotos[currentSubsection];
        }
        this.setState({ photosUpdated: updatedPhotos });
      }
      this.fetchSurveyDetails();
    }
    
  };

  handleFilesData = () =>{
    const {bulkUploadingResponse, fieldDetails, currentFields, subSectionNew, photosUpdated} = this.state;
    const { photo_details, media_details } = bulkUploadingResponse.data.attributes;
    const newPicture = photo_details ? photo_details : [];
    const newVideo = media_details ? media_details : [];
    const mergeMedia = newPicture.concat(newVideo);
    const media_id = bulkUploadingResponse.data.attributes?.id;
    const currentSubsection:string = subSectionNew;
    const fieldId = currentFields.find(value => value.field_name === fieldDetails.data.survey_field_detail.field_name)?.id;
    const currentPhotos = photosUpdated[currentSubsection] || [];
    const updatedPhotos = {
      ...photosUpdated,
      [currentSubsection]: [
        ...(currentPhotos || []),
        ...mergeMedia.map(item => ({
        ...item,
        media_id: media_id,
        subSectionNew: currentSubsection,
        fieldId
    }))
      ]
    };
    this.setState({
      photosUpdated: updatedPhotos,
      formData: new FormData(),
      limitValidationError: '',
      limitVideoValidationError: ''
    });
    setTimeout(() => {
      this.setState({
        openUploadModal: false,
        uploadingLoader: false,
        selectedImages:{},
        selectedVideos:{}
      });
    }, 1500);
  };

  handleAddDescription = async(postData: { user_survey_media_id: number; photo_id?: number; video_id?:number; description: string;}, subSectionName: string | undefined) =>{
    const {
      subSectionNew,
      photosUpdated,
    } = this.state;
    const mediaId = postData.photo_id || postData.video_id;
    const newDescription = postData.description;
    const currentSubsection = subSectionName ?? subSectionNew;
    const currentPhotos = photosUpdated[currentSubsection] || [];
    const updatedPhotos = {
      ...photosUpdated,
      [currentSubsection]: currentPhotos.map(item => {
        const matchedMedia = mediaId === item.id;
        if (matchedMedia) {
          return {
            ...item,
            description: newDescription
          };
        }
        return item;
      })
    };
    this.setState({ photosUpdated: updatedPhotos }, async ()=>{
    this.addDescriptionApiCallId = await this.apiCallSurvey({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_survey/user_survey_media/user_survey_uploaded_files_description`,
      body: postData
    });
  });
  };

  handleScrollToHeading = (subsectionName: string) => {
    const headingRef = this.headingRefs[subsectionName];
    if (headingRef && headingRef.current) {
      const yOffset = -100;
      const y = headingRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  }
  };
  
  handleCheckFileListLength = (fileList:File[]) => {
    if (this.state.totalFiles + fileList.length > 10) {
      this.setState({
        fileLimitExceeded: true,
        limitValidationError: "",
        limitVideoValidationError:'',
        isPermissionModalOpen: false,
        isFileContainerOpen: true
      });
      return;
    }
  }

imgFilehandlerSubsection = async (eventHandleFile: ChangeEvent<HTMLInputElement>) => {
  const files = eventHandleFile.target.files;
  if (files) {
    const fileList = Array.from(files);
    this.handleCheckFileListLength(fileList);
    const imageFiles = fileList.filter(file => this.isImage(file));
    const videoFiles = fileList.filter(file => this.isVideo(file));
    const imageSizeLimit = 10 * 1024 * 1024;
    const videoSizeLimit = 20 * 1024 * 1024;

    const oversizedImages = imageFiles.filter(file => file.size > imageSizeLimit);
    const oversizedVideos = videoFiles.filter(file => file.size > videoSizeLimit);
    const videoNames = oversizedVideos.map(file => file.name).join(', ');
    const imageNames = oversizedImages.map(file => file.name).join(', ');

    if (oversizedImages.length > 0) {
      this.setState({
        limitValidationError: `Name ${imageNames} file size exceeds maximum limit. Maximum allowed file size is 10 MB`,
        isPermissionModalOpen: false, isCameraModalOpen: false, isFileContainerOpen: true, isGalleryAccessAllowed: true
      }, () => {
        this.handleErrorPopup({ limitValidationError: '' });
      });
    }
    if (oversizedVideos.length > 0) {
      this.setState({
        limitVideoValidationError: `Name ${videoNames} file size exceeds maximum limit. Maximum allowed file size is 20 MB`,
        isPermissionModalOpen: false, isCameraModalOpen: false, isFileContainerOpen: true, isGalleryAccessAllowed: true
      }, () => {
        this.handleErrorPopup({ limitVideoValidationError: '' });
      });
    }

    const compressImageFile = async (file:File) => {
      const options = {
        maxSizeMB: 0.5, 
        maxWidthOrHeight: 1920, 
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        
        return compressedFile;
      } catch (error) {
        console.error("Error compressing image:", error);
        return file;
      }
    };

    const compressedImages = await Promise.all(imageFiles.map(file => compressImageFile(file)));
    const filterSizedImages = compressedImages.filter(file => file.size <= imageSizeLimit);
    const filterSizedVideos = videoFiles.filter(file => file.size <= videoSizeLimit);

    if(filterSizedVideos.length === 0 && filterSizedImages.length === 0){
      return;
    } else {
      this.setState(prevState => ({
        totalFiles: prevState.totalFiles + fileList.length,
        uploadedImages: {
          ...prevState.uploadedImages,
          [prevState.subSectionNew]: [
            ...(prevState.uploadedImages[prevState.subSectionNew] || []),
            ...filterSizedImages
          ]
        },
        selectedImages: {
          [prevState.subSectionNew]: [
            ...filterSizedImages
          ]
        },
        uploadedVideos: {
          ...prevState.uploadedVideos,
          [prevState.subSectionNew]: [
            ...(prevState.uploadedVideos[prevState.subSectionNew] || []),
            ...filterSizedVideos
          ]
        },
        selectedVideos: {
          [prevState.subSectionNew]: [
            ...filterSizedVideos
          ]
        },
        fileLimitExceeded: false
      }));
    }

    this.setState({
      ispermissionGranted: true,
      isPermissionModalOpen: false,
      isCameraModalOpen: false,
      isFileContainerOpen: true
    });
  }
};



  handleFileContainerClick = (eventFields: React.MouseEvent) =>{
    eventFields.preventDefault()
    this.setState({ isFileContainerOpen: false })
  };

  galleryAccessPermission = () => {
    this.setState({ isGalleryAccessAllowed: false });
  };

  permissionGranted = () => {
    this.setState({ ispermissionGranted: true });
  };

  handleGalleryClick = () =>{
    this.setState({ isPermissionModalOpen: true, isCameraModalOpen: false });
  };

  handleCameraClick = () =>{
    this.setState({ isCameraModalOpen: true, isPermissionModalOpen: false });
  };
  goBack=()=>{
    this.setState({
      isPermissionModalOpen:false, isCameraModalOpen: false, isFileContainerOpen: true, isGalleryAccessAllowed: true
    })
  };

  handleDrowerToggle = () => {
    this.setState({hasOpenSidebar:!this.state.hasOpenSidebar})
  };

  fetchSurveyDetails = async() => {
    const URL = window.location.href;
    const segments = URL.split('/');
    const shareId = segments[segments.length - 2];
    const token = await storage.get("auth-token")
    
    sendRequest(
      `bx_block_survey/users_survey/${shareId}`,
      "GET",
      (id) => (this.getSurveyDetailsCallId = id),
      null,
      {
        token,
      }
    );
  }
  // Customizable Area End
}