// Customizable Area Start
import React from "react";
import {
  Box,
  InputAdornment,
  styled,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { searchImg, searchMobileImg } from "./assets";
import { TextFieldCustom } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GlobalSearchBarController from "./GlobalSearchbarController";
import SearchIcon from '@material-ui/icons/Search';

const styles = () => ({
  inputAdornmentContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
});

const CustomBox = styled(Box)({
  "@media(max-width: 900px)":{
    padding: "0 24px"
  }
})

const SearchImg =styled("img")({
  paddingLeft: '40px',
   width: '24px',
   height: '24px',
  "@media(max-width: 900px)":{
   width: '16px',
   height: '16px'
  }
})

const MobileSearchIcon = styled(SearchIcon)({
  display: "none",
  "@media(max-width: 900px)":{
    display:'block',
    marginLeft:'8px',
    transform:"scale(1.5)"
  },
  "@media(max-width: 550px)":{
    transform:"scale(1)"
  }
})

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    display: 'flex',
    justifyContent: 'center',
    "@media(max-width: 550px)":{
    padding:0,
      height: "40px",
      
    },
  "@media(max-width: 435px)":{
    padding:0,
    flexWrap:'nowrap'
  }

  },
  '& .MuiAutocomplete-input': {
    flexGrow: 0,
    width: '315px',
    padding: 0,
    "@media(max-width: 550px)":{
      width: "300px"
    },
    "@media(max-width: 435px)":{
      width: '100%'
    }
  },
  '& .MuiOutlinedInput-input':{
    height: "auto"
  }
}) as typeof Autocomplete;

interface CenteredInputAdornmentProps extends WithStyles<typeof styles> {
  onClick: () => void;
}

class CenteredInputAdornment extends BlockComponent<
  CenteredInputAdornmentProps,
  {},
  {}
> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.inputAdornmentContainer}>
        <SearchImg
          width={"26px"}
          height={"26px"}
          src={searchImg}
          alt="Search"
          data-test-id="search-btn"
          onClick={this.props.onClick}
        />
      </div>
    );
  }
}

const StyledCenteredInputAdornment = withStyles(styles)(CenteredInputAdornment);

export class GlobalSearchBar extends GlobalSearchBarController {
  render() {
    return (
      <CustomBox>
        <img
          src={searchMobileImg}
          alt="Mobile Search Icon"
          style={{
            width: "17px",
            height: "17px",
            display: "none",
            cursor: "pointer",
          }}
        />
        <CustomAutocomplete
          options={this.state.options}
          getOptionLabel={(option) => option}
          onChange={this.handleChange}
          onBlur={this.clearOptions}
          value={this.fieldValue as any}
          filterOptions={(x) => x}
          disableClearable
          popupIcon={null}
          noOptionsText={"No options"}
          renderInput={(params) => (
            <TextFieldCustom
              {...params}
              placeholder="Search anywhere in the survey report"
              variant="outlined"
              onChange={this.handleInputChange}
              data-test-id="globalSearchInput"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledCenteredInputAdornment onClick={this.search} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </CustomBox>
    );
  }
}
// Customizable Area End
