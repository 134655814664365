import React, { createRef } from "react";
// Customizable Area Start
import {
    styled, AppBar, IconButton, Toolbar, Box, Typography, List, ListItem, Collapse, Drawer, Button, Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    withStyles,
    Modal,
    Fade,
    Backdrop,
    TextField,
    InputAdornment,
    WithStyles,
} from "@material-ui/core";
import GuestDashboardController, {
    MediaFiles,
    NavigationSection,
    NavigationSubSection,
    Props,
    SurveySubSection,
    SurveySubSectionDetails,
    configJSON
} from "./GuestDashboardController.web";
import { mainLogo, hamburger, fullSize, download, errorLogo, back, next, searchMobileImg, searchImg } from "./assets";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Close from "@material-ui/icons/CloseRounded";
import SaveAsPdf from "../../saveaspdf/src/SaveAsPdf.web";
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { TextFieldCustom } from "../../email-account-login/src/EmailAccountLoginBlock.web";
const MobileSearchIcon = styled(SearchIcon)({
    display: "none",
    "@media(max-width: 900px)": {
        display: 'block',
        marginLeft: '8px',
        transform: "scale(1.5)"
    },
    "@media(max-width: 550px)": {
        transform: "scale(1)"
    }
})

const SearchImg = styled("img")({
    "@media(max-width: 900px)": {
        display: "none"
    }
})

interface CenteredInputAdornmentProps extends WithStyles<typeof styles> {
    onClick: () => void;
}

const CenteredInputAdornment = (onClick: any) => {
    return (
        <div className="centered" style={{ display: "flex", justifyContent: "center" }}>
            <SearchImg
                width={"26px"}
                height={"26px"}
                src={searchImg}
                alt="Search"
                data-test-id="search-btn"
                onClick={onClick}
            />
            <MobileSearchIcon
                onClick={onClick}
            />
        </div>
    );
}


const centerStyle = () => ({
    inputAdornmentContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
    },
    centered: {
        display: "flex",
        justifyContent: "center"
    }
});

const SearchSectionBox = styled(Box)({
    borderRadius: "0px 0px 8px 8px",
    background: "#EBF5FE",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    paddingBottom: "20px",
    position: 'absolute',
    width: '18.5%',
    '@media (max-width: 900px)': {
        width: '100%',
    },
})

const SearchInput = styled(TextFieldCustom)({
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 70%)",
    padding: 1,
    width: '100%',
    paddingTop: "2px",
    '@media (max-width: 900px)': {
        width: '100%',
    },

    "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        background: 'white'
    },

    "& .MuiOutlinedInput-input": {
        padding: "16px 4px !important",

    },

    "& .MuiOutlinedInput-adornedStart": {
        paddingLeft: '8px',
    },

    "& .MuiOutlinedInput-input::placeholder": {
        fontSize: '18px',
    }
});

const StyledCenteredInputAdornment = withStyles(centerStyle)(CenteredInputAdornment);
// Customizable Area End

export default class GuestDashboard extends GuestDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderTopHeader() {
        return (
            <>
                <HeaderWrapper position="fixed" data-testid="containerId">
                    <AppToolbar>
                        <MenuButton data-testid="drawerId" onClick={() => { this.handleDrawer() }}>
                            <img src={hamburger} alt="hamburger" />
                        </MenuButton>
                        <LogoButton>
                            <Logo src={mainLogo} />
                        </LogoButton>
                    </AppToolbar>
                </HeaderWrapper>
            </>
        )
    }

    renderMainContainer() {
        return (
            <Box>
                <MainDetailsContainer>
                    <BackArrowContainer>
                        <MainHeading>{configJSON.mainHeading}</MainHeading>
                    </BackArrowContainer>
                    <MobileDownloadButton>
                        <DownloadContainer>
                            <PdfDownload onClick={this.downloadPDF}>{configJSON.downloadPDF}</PdfDownload>
                            <PdfDownload data-testid="mobileZipId" onClick={this.downloadZip}>{this.state.zipLoader ? "Downloading..." : configJSON.downloadAsZIP}</PdfDownload>
                        </DownloadContainer>
                    </MobileDownloadButton>

                    <GridParentContainer>
                        <SideBarNav>
                            {this.renderNavigation()}
                        </SideBarNav>
                        <CustomDrawer open={this.state.openDrawer}>
                            {this.renderNavigation()}
                        </CustomDrawer>
                        <DetailsContainer>
                            <Box>
                                <img
                                    src={searchMobileImg}
                                    alt="Mobile Search Icon"
                                    style={{
                                        width: "17px",
                                        height: "17px",
                                        display: "none",
                                        cursor: "pointer",
                                    }}
                                />
                                <style>
                                    {`
                                    .MuiAutocomplete-input{
                                        // text-align: center;
                                        flex: 0.3;
                                    }
                                    .MuiInputAdornment-root{
                                        text-align: center;
                                    }
                                    .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
                                        display: flex;
                                        justify-content: center;
                                    }
                                    .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
                                        border-radius: 8px;
                                    }
                                    .MuiAutocomplete-input::placeholder {
                                        font-size:20px;
                                        font-family: Poppins;
                                    }
                                `}
                                </style>

                                <Autocomplete
                                    options={this.state.option}
                                    getOptionLabel={(option: any) => option.label}
                                    onChange={this.handleChange}
                                    value={this.fieldValue}
                                    filterOptions={this.filterOptions}
                                    disableClearable
                                    popupIcon={null}
                                    data-test-id="Autocomplete"
                                    noOptionsText={"No options"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search anywhere in the survey report"
                                            variant="outlined"
                                            data-test-id="globalSearchInput"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <StyledCenteredInputAdornment onClick={this.search} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    style={{ borderRadius: "12px" }}
                                />
                            </Box>
                            {this.renderDetailsContainer()}
                        </DetailsContainer>
                    </GridParentContainer>
                </MainDetailsContainer>
                <CustomModalContainer
                    data-testid="zipErrorId"
                    open={this.state.openPopup}
                    BackdropComponent={Backdrop}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500
                    }}>
                    <CustomModal in={this.state.openPopup}>
                        <Box>
                            <img src={errorLogo} alt="danger" />
                            <ErrorText variant="h4">{this.state.winZipLink}</ErrorText>
                            <ErrorCloseButton onClick={this.fileErrorPopup}>{configJSON.closeButton}</ErrorCloseButton>
                        </Box>
                    </CustomModal>
                </CustomModalContainer>
            </Box>
        )
    }

    renderNavigation() {
        return (
            <>
                <SideBarBorder>
                    <CrossIconContainer>
                        <Close data-testid="drawerCloseId" onClick={() => this.handleDrawer()} />
                    </CrossIconContainer>
                    <List component="nav" data-testid="toggleId">
                        {this.state.navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.map((section: NavigationSection) => (
                            <React.Fragment key={section.id}>
                                <ListItemSection data-testid="toggleSectionId" button disableTouchRipple onClick={() => this.toggleSection(section.id)} className={this.state.sectionSelectedId == section.id ? "isSelected" : ""}>
                                    {section.survey_section_name}
                                    {section.user_survey_sub_sections.length > 0 && (this.state.openSections.has(section.id) ? <ExpandLess /> : <ExpandMore />)}
                                </ListItemSection>
                                {section.user_survey_sub_sections.length > 0 && (
                                    <Collapse data-testid="collapsedId" in={!this.state.collapsed && this.state.openSections.has(section.id)} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding key={section.id}>
                                            {section.user_survey_sub_sections.map((subSection: NavigationSubSection) => (
                                                <ListItemSubsection
                                                    data-testid="accordionId"
                                                    onClick={() => this.handleSubsectionAccordions(section.id, subSection.survey_sub_section_name)}
                                                    button
                                                    disableTouchRipple
                                                    key={subSection.id}>
                                                    {subSection.survey_sub_section_name}
                                                </ListItemSubsection>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </SideBarBorder>
                <Box>
                    <SearchInput
                        placeholder="Jump to specific section"
                        variant="outlined"
                        value={this.state.searchSpecificSec}
                        data-test-id="searchSpecSection"
                        style={{ marginTop: "24px" }}
                        onChange={(e: any) => this.handleSectionSearchBar(e)}
                        InputProps={{
                            startAdornment: (
                                <img
                                    width={"17px"}
                                    height={"17px"}
                                    src={searchImg}
                                    alt="search icon"
                                />
                            ),
                        }}
                    />
                    {this.state.searchSpecificSec.length ? <SearchSectionBox>
                        {this.state.navigationSearchSections.map((section: any, idx: number) => {
                            return <React.Fragment key={idx}>
                                <Typography 
                                style=
                                {{
                                    color: "#000",
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    cursor: "pointer",
                                    padding: "7px 0px 7px 23px",
                                }}
                                    onClick={() =>
                                        this.onLinkClick(section.id)
                                    }
                                    data-test-id="custom-form-link2"
                                >
                                    {section.survey_section_name}
                                </Typography>
                            </React.Fragment>
                        })}

                    </SearchSectionBox> : null}
                </Box>
            </>
        )
    }

    renderDetailsContainer() {
        return (
            <DetailedScreenContainer>
                {this.state.sectionSelectedId == this.state.sectionIdOneTime && this.renderBasicInformation()}
                {this.renderMainSectionInformation()}
                {this.renderBottomButtons()}
            </DetailedScreenContainer>
        )
    }

    renderBasicInformation() {
        return (
            <BasicContainerBorder>
                <SurveyHeading>{this.state.surveyInfo.data.attributes.survey_name}</SurveyHeading>
                <InnerDetails>
                    <BorderDetails>
                        <MobileDetailsContainer>
                            <BasicRow>
                                <Headings>{configJSON.dateCreated}</Headings>
                                <Headings>{configJSON.lastEdited}</Headings>
                                <Headings>{configJSON.createdBy}</Headings>
                                <Headings>{configJSON.owner}</Headings>
                            </BasicRow>
                            <BasicRow>
                                <ParaDetails>{this.state.surveyInfo.data.attributes.date_created.split("-").reverse().join("/")}</ParaDetails>
                                <ParaDetails>{this.state.surveyInfo.data.attributes.last_edited.split("-").reverse().join("/")}</ParaDetails>
                                <ParaDetails>{this.state.surveyInfo.data.attributes.created_by}</ParaDetails>
                                <ParaDetails>{this.state.surveyInfo.data.attributes.owner}</ParaDetails>
                            </BasicRow>
                        </MobileDetailsContainer>
                        <MainTableContainer>
                            <TableHead>
                                <CustomTableRowHead>
                                    <CustomTableCellFirst>{configJSON.dateCreated}</CustomTableCellFirst>
                                    <CustomTableCellFirst>{configJSON.lastEdited}</CustomTableCellFirst>
                                    <CustomTableCellFirst>{configJSON.createdBy}</CustomTableCellFirst>
                                    <CustomTableCellFirst>{configJSON.owner}</CustomTableCellFirst>
                                </CustomTableRowHead>
                            </TableHead>
                            <TableBody>
                                <CustomTableRowBody>
                                    <CustomTableCellSecond>{this.handleFormateDate(this.state.surveyInfo.data.attributes.date_created)}</CustomTableCellSecond>
                                    <CustomTableCellSecond>{this.handleFormateDate(this.state.surveyInfo.data.attributes.last_edited)}</CustomTableCellSecond>
                                    <CustomTableCellSecond>{this.state.surveyInfo.data.attributes.created_by.length > 12 ? (this.state.surveyInfo.data.attributes.created_by.slice(0, 10) + "...") : this.state.surveyInfo.data.attributes.created_by}</CustomTableCellSecond>
                                    <CustomTableCellSecond>{this.state.surveyInfo.data.attributes.owner.length > 12 ? (this.state.surveyInfo.data.attributes.owner.slice(0, 10) + "...") : this.state.surveyInfo.data.attributes.owner}</CustomTableCellSecond>
                                </CustomTableRowBody>
                            </TableBody>
                        </MainTableContainer>
                    </BorderDetails>
                    <DownloadButton>{this.renderDownloadButtons()}</DownloadButton>
                </InnerDetails>
            </BasicContainerBorder>
        )
    }

    renderDownloadButtons() {
        return (
            <DownloadContainer>
                <PdfDownload data-testid="pdfReportDownloadId" onClick={this.downloadPDF}>{configJSON.downloadAsPDF}</PdfDownload>
                <PdfDownload data-testid="zipDownloadId" onClick={this.downloadZip}>{this.state.zipLoader ? "Downloading..." : configJSON.downloadAsZIP}</PdfDownload>
            </DownloadContainer>
        )
    }

    renderMainSectionInformation() {
        return (
            <>
                {this.state.sectionDetails.data.attributes.is_subsection_present ? (
                    <BasicContainerAccordion>
                        <SurveyFormHeading>{this.state.sectionDetails.data.attributes.user_survey_section_name}</SurveyFormHeading>
                        {this.state.sectionDetails.data.attributes.user_survey_sub_sections.map((section: SurveySubSection) => {
                            const subSectionRef = createRef<HTMLDivElement>();
                            this.headingRefs[section.user_survey_sub_section_name] = subSectionRef;
                            return (
                                <CustomAccordionContainer key={section.id}>
                                    <CustomNewAccordion
                                        innerRef={subSectionRef}
                                        data-testid="accordionsId"
                                        onClick={() => this.handleAccordion(section.id)}>
                                        <CustomNewAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                            {section.user_survey_sub_section_name}
                                            {this.state.accordionOpenState[section.id] ? <ExpandLess /> : <ExpandMore />}
                                        </CustomNewAccordionSummary>
                                        {
                                            this.state.accordionOpenState[section.id] && 
                                        <CustomAccordionDetails>
                                            {section.user_survey_sub_section_details.map((subsection: SurveySubSectionDetails) => (
                                                <AccordionContainerDetails key={subsection.id}>
                                                    <FieldsName>{subsection.field_name}:</FieldsName>
                                                    <FieldsValueDetails>{subsection.field_value}</FieldsValueDetails>
                                                    {
                                                        subsection.photos.length > 0 && (
                                                            subsection.photos.map((subSectionPhotosItem: MediaFiles, subSectionPhotoIndex: number) => {
                                                                return (
                                                                    <ImageMainContainer>
                                                                        <FileSizeContainer key={subSectionPhotoIndex}>
                                                                            <ImageFiles src={subSectionPhotosItem.url} alt="subfile" />
                                                                            <FilesDescription>{subSectionPhotosItem.description}</FilesDescription>
                                                                        </FileSizeContainer>
                                                                        <DownloadIconSubSectionMobileScreen>
                                                                            <img src={download} alt="subdownload" />
                                                                        </DownloadIconSubSectionMobileScreen>
                                                                        <ViewFullImage>
                                                                            <ViewContainer data-testid="viewsubPhotoId" onClick={() => this.handleViewSize(subSectionPhotosItem.url)}>
                                                                                <ViewFullSize>{configJSON.viewFullSize}</ViewFullSize>
                                                                                <img src={fullSize} alt="subfullsizes" data-testid="subphotoId" />
                                                                            </ViewContainer>
                                                                            <ViewContainer data-testid="downloadImage" onClick={() => this.handleDownloadImage(subSectionPhotosItem.url, subSectionPhotosItem.filename)}>
                                                                                <ViewFullSize data-testid="downloadFilesId">{configJSON.downloadFiles}</ViewFullSize>
                                                                                <img src={download} alt="subdownloads" />
                                                                            </ViewContainer>
                                                                        </ViewFullImage>
                                                                    </ImageMainContainer>
                                                                )
                                                            })
                                                        )}
                                                    {
                                                        subsection.videos.length > 0 && (
                                                            subsection.videos.map((subSectionVideosItem: MediaFiles, subSectionVideoIndex: number) => {
                                                                this.videoRefs = subsection.videos.map(() => createRef<HTMLVideoElement>());
                                                                const videoRef = this.videoRefs[subSectionVideoIndex];
                                                                return (
                                                                    <ImageMainContainer>
                                                                        <FileSizeContainer>
                                                                            <VideoFiles controls key={subSectionVideoIndex} ref={videoRef} data-testid="refsSubId">
                                                                                <source src={subSectionVideosItem.url} type={subSectionVideosItem.content_type} />
                                                                            </VideoFiles>
                                                                            <FilesDescription>{subSectionVideosItem.description}</FilesDescription>
                                                                        </FileSizeContainer>
                                                                        <DownloadIconSubSectionMobileScreen data-testid="videoSubId" onClick={() => this.handleViewSize(subSectionVideosItem.url)}>
                                                                            <img src={download} alt="subVideoDownload" />
                                                                        </DownloadIconSubSectionMobileScreen>
                                                                        <ViewFullImage>
                                                                            <ViewContainer data-testid="viewSubVideoId" onClick={() => this.handleFullScreen(videoRef)}>
                                                                                <ViewFullSize>{configJSON.viewFullSizeVideo}</ViewFullSize>
                                                                                <img src={fullSize} alt="fullsubVideo" data-testid="videoId" />
                                                                            </ViewContainer>
                                                                            <ViewContainer data-testid="downloadsubVideoId" onClick={() => this.handleViewSize(subSectionVideosItem.url)}>
                                                                                <ViewFullSize data-testid="downloadsubTextId">{configJSON.downloadFiles}</ViewFullSize>
                                                                                <img src={download} alt="subfiledownload" />
                                                                            </ViewContainer>
                                                                        </ViewFullImage>
                                                                    </ImageMainContainer>
                                                                )
                                                            })
                                                        )}
                                                </AccordionContainerDetails>
                                            ))}
                                        </CustomAccordionDetails>
                                        }
                                    </CustomNewAccordion>
                                </CustomAccordionContainer>
                            )
                        })}
                    </BasicContainerAccordion>
                ) : (
                    <BasicContainerBorder>
                        <SurveyFormHeading>{this.state.sectionDetails.data.attributes.user_survey_section_name}</SurveyFormHeading>
                        {this.state.sectionDetails.data.attributes.user_survey_sub_sections.map((section: SurveySubSection) => (
                            section.user_survey_sub_section_details.map((subsection: SurveySubSectionDetails) => (
                                <MainContainerDetails key={subsection.id}>
                                    <FieldsName data-testid="sectionsId">{subsection.field_name}: </FieldsName>
                                    <FieldsValueDetails>{subsection.field_value}</FieldsValueDetails>
                                    {
                                        subsection.photos.length > 0 ?
                                            subsection.photos.map((photosItem: MediaFiles, photoIndex: number) => {
                                                return (
                                                    <ImageMainContainer>
                                                        <FileSizeContainer key={photoIndex}>
                                                            <ImageFiles src={photosItem.url} alt="file" />
                                                            <FilesDescription>{photosItem.description}</FilesDescription>
                                                        </FileSizeContainer>
                                                        <DownloadIconForMobileScreen>
                                                            <img src={download} alt="Download" />
                                                        </DownloadIconForMobileScreen>
                                                        <ViewFullImage>
                                                            <ViewContainer data-testid="viewPhotoId" onClick={() => this.handleViewSize(photosItem.url)}>
                                                                <ViewFullSize>{configJSON.viewFullSize}</ViewFullSize>
                                                                <img src={fullSize} alt="fullsizes" data-testid="photoId" />
                                                            </ViewContainer>
                                                            <ViewContainer data-testid="downloadImageId" onClick={() => this.handleDownloadImage(photosItem.url, photosItem.filename)}>
                                                                <ViewFullSize>{configJSON.downloadFiles}</ViewFullSize>
                                                                <img src={download} alt="downloads" />
                                                            </ViewContainer>
                                                        </ViewFullImage>
                                                    </ImageMainContainer>
                                                )
                                            })
                                            :
                                            ""
                                    }
                                    {
                                        subsection.videos.length > 0 ?
                                            subsection.videos.map((videosItem: MediaFiles, videoIndex: number) => {
                                                this.videoRefs = subsection.videos.map(() => createRef<HTMLVideoElement>());
                                                const videoRef = this.videoRefs[videoIndex];
                                                return (
                                                    <ImageMainContainer>
                                                        <FileSizeContainer>
                                                            <VideoFiles controls key={videoIndex} ref={videoRef} data-testid="refsId">
                                                                <source src={videosItem.url} type={videosItem.content_type} />
                                                            </VideoFiles>
                                                            <FilesDescription>{videosItem.description}</FilesDescription>
                                                        </FileSizeContainer>
                                                        <DownloadIconForMobileScreen data-testid="viewDownloadId" onClick={() => this.handleViewSize(videosItem.url)}>
                                                            <img src={download} alt="Download" />
                                                        </DownloadIconForMobileScreen>
                                                        <ViewFullImage>
                                                            <ViewContainer data-testid="viewVideoId" onClick={() => this.handleFullScreen(videoRef)}>
                                                                <ViewFullSize>{configJSON.viewFullSizeVideo}</ViewFullSize>
                                                                <img src={fullSize} alt="fullsize" data-testid="videoId" />
                                                            </ViewContainer>
                                                            <ViewContainer data-testid="downloadVideoId" onClick={() => this.handleViewSize(videosItem.url)}>
                                                                <ViewFullSize data-testid="downloadVideoTextId">{configJSON.downloadFiles}</ViewFullSize>
                                                                <img src={download} alt="download" />
                                                            </ViewContainer>
                                                        </ViewFullImage>
                                                    </ImageMainContainer>
                                                )
                                            })
                                            :
                                            ""
                                    }
                                </MainContainerDetails>
                            ))
                        ))}
                    </BasicContainerBorder>
                )}
            </>
        )
    }

    renderFailureInformation() {
        return (
            <>
                <CustomModalContainer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    data-testid="modalId"
                    open={this.state.openModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}>
                    <CustomModal in={this.state.openModal}>
                        <Box>
                            <img src={errorLogo} alt="danger" />
                            <ErrorText variant="h4">{configJSON.errorMessage}</ErrorText>
                        </Box>
                    </CustomModal>
                </CustomModalContainer>
            </>
        )

    }

    renderMainDetails() {
        return (
            <Box>
                {this.renderTopHeader()}
                {this.renderMainContainer()}
            </Box>
        )
    }

    renderBottomButtons() {
        return (
            <ButtonMainContainer>
                <BackButton data-testid="previousButtonId" startIcon={<BackButtonArrow src={back} alt="back" />} onClick={() => this.previousPagination()} disabled={this.state.hasPreviousSection} >{configJSON.backButton}</BackButton>
                <NextButton data-testid="nexButtonId" endIcon={<NextButtonArrow src={next} alt="next" />} onClick={() => this.nextPagination()} disabled={this.state.hasNextSection}>{configJSON.nextButton}</NextButton>
            </ButtonMainContainer>
        )
    }

    renderSaveAsPdf() {
        return (
            <SaveAsPdf
                navigation={this.props.navigation}
                id={this.props.id}
                surveyName={configJSON.headingTemp}
                created={configJSON.created}
                lastEdited={configJSON.lastEdited}
                createdBy={configJSON.createdBy}
                owner={configJSON.owner}
                matchId={configJSON.headingTemp}
                fields={configJSON.headingTemp}
                fieldValues={configJSON.headingTemp}
                sectionPdfDetails={this.state.saveAsPdfDetails}
            />
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <Box data-testid="container">
                {!this.state.openModal && this.renderMainDetails()}
                <Box id="pdfId" position={"fixed"} display={"none"} width={"1440px"}>{this.renderSaveAsPdf()}</Box>
            </Box>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const BackArrowContainer = styled(Box)({
    "@media(max-width:992px)": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "26px 0px 26px 0px",
        gap: "15px"
    }
});

const ImageMobile = styled(Box)({
    display: "none",
    "@media(max-width:992px)": {
        display: "block"
    }
});

const CustomDrawer = styled(Drawer)({
    display: "none",
    "@media(max-width:992px)": {
        display: "block",
        width: "100%",
        "& .MuiPaper-root": {
            width: "100%"
        }
    }
});

const CrossIconContainer = styled(Box)({
    display: "none",
    "@media(max-width:992px)": {
        display: "block",
        color: "white",
        margin: "15px 0px 0px 16px"
    }
});

const ListItemSubsection = styled(ListItem)({
    paddingLeft: "40px",
    color: "#424C53",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    "&:hover": {
        backgroundColor: "transparent"
    },
    "@media(max-width:992px)": {
        color: "#ffffff",
        marginLeft: "10px"
    }
});

const ListItemSection = styled(ListItem)({
    fontWeight: 400,
    color: "#424C53",
    fontSize: "18px",
    fontFamily: "Poppins",
    "&.isSelected": {
        color: "#4FC3FD"
    },
    "&:hover": {
        backgroundColor: "transparent"
    },
    "@media(max-width:992px)": {
        color: "#ffffff",
        fontWeight: 500,
        marginLeft: "10px"
    }
});

const GridParentContainer = styled(Box)({
    padding: "30px",
    display: "flex",
    gap: "20px",
    "@media(max-width: 992px)": {
        padding: "0px",
        marginTop: "18px",
        marginBottom: "18px"
    }
});

const SideBarBorder = styled(Box)({
    width: "100%",
    fontSize: "16px",
    borderRadius: "8px",
    border: "solid 1px transparent",
    backgroundImage: "linear-gradient(white, white),linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 70%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    cursor: "pointer",
 "@media(max-width:992px)": {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        width: "100%",
        background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 70%)",
        color: "#fff",
        borderRadius: "0px 20px 20px 0px",
        height: "100%"
    }
});

const SideBarNav = styled(Box)({
    width: "24%",
    "@media(max-width:992px)": {
        display: "none"
    }
});

const HeaderWrapper = styled(AppBar)({
    backgroundColor: "white",
    borderBottom: "1px",
    borderColor: "rgba(255, 255, 255, 1)",
    height: "75px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "@media(max-width: 435px)": {
        height: "48px",
    }
});

const AppToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    "@media(max-width: 992px)": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"
    }
});

const Logo = styled("img")({
    width: "119px",
    height: "60px",
    margin: "0",
    "@media(max-width: 435px)": {
        height: "36px",
        width: "71px"
    }
});

const LogoButton = styled(IconButton)({
    margin: 0,
    padding: 0,
    background: "transparent",
    "@media(max-width: 435px)": {
        height: "36px",
        width: "71px"
    }
});

const MenuButton = styled(IconButton)({
    display: "none",

    "@media(max-width: 992px)": {
        display: "block",
        position: "absolute",
        left: 20
    },
    "@media(max-width: 550px)": {
        left: 10
    }
});

const MainDetailsContainer = styled(Box)({
    marginTop: "130px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "@media(max-width:992px)": {
        marginTop: "80px",
        padding: "0px 20px 0px 20px"
    },
    "@media(max-width: 435px)": {
        marginTop: "48px",
    }
});

const MainHeading = styled(Typography)({
    fontSize: "30px",
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#181818",
    paddingLeft: "30px",
    "@media(max-width:992px)": {
        fontSize: "18px",
        fontWeight: 500,
        color: "#191C1E",
        paddingLeft: "0px",
    }
});

const BasicContainerBorder = styled(Box)({
    border: "1px solid transparent",
    borderRadius: "16px",
    width: "95%",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#FFFFFF",
    padding: "20px 40px 40px 0px",
    "@media(max-width: 992px)": {
        width: "100%",
        padding: "0px 0px 10px 0px"
    }
});

const DetailsContainer = styled(Box)({
    width: "100%",
});

const BasicRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "25px",
    "@media(max-width: 575px)": {
        flexDirection: "column",
        gap: "15px",
        paddingRight: "0px",
        width: "35%"
    }
});

const InnerDetails = styled(Box)({
    display: "flex",
    width: "100%"
});

const BorderDetails = styled(Box)({
    width: "65%",
    padding: "10px 40px 10px 40px",
    "@media(max-width: 992px)": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px 10px 20px"
    },
    "@media(max-width: 575px)": {
        width: "90%"
    }
});

const DownloadButton = styled(Box)({
    display: "flex",
    flexDirection: "column",
    "@media(max-width: 992px)": {
        display: "none"
    }
});

const DownloadContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    paddingTop: "20px",
    "@media(max-width: 992px)": {
        flexDirection: "row",
        paddingTop: "0px",
        justifyContent: "space-between"
    }
});

const PdfDownload = styled(Button)({
    fontSize: "16px",
    backgroundColor: "#EBF5FE",
    color: "#0090CF",
    fontWeight: 500,
    fontFamily: "Poppins",
    height: "44px",
    borderRadius: "8px",
    textTransform: "none",
    padding: "0px 16px 0px 16px",
    "&:hover": {
        backgroundColor: "#EBF5FE",
        color: "#0090CF"
    },
    "@media(max-width: 1138px)": {
        fontSize: "16px"
    },
    "@media(max-width: 992px)": {
        fontSize: "11.25px",
        width: "100%"
    }
});

const MobileDownloadButton = styled(Box)({
    display: "none",
    "@media(max-width: 992px)": {
        display: "block"
    }
});

const CustomTableRowHead = styled(TableRow)({
    "& .MuiTableCell-root": {
        borderBottom: "1px solid #424C53",
        padding: "16px 0px 5px 0px"
    },
    "@media(max-width: 500px)": {
        "& .MuiTableCell-root": {
            width: "60%",
        },
    },
});

const CustomTableRowBody = styled(TableRow)({
    "& .MuiTableCell-root": {
        borderBottom: "none",
        padding: "36px 0px 5px 0px"
    },
    "@media(max-width: 500px)": {
        "& .MuiTableCell-root": {
            padding: "9px 0px"
        }
    }
});

const CustomTableCellFirst = styled(TableCell)({
    color: "#424C53",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    "@media(max-width: 1024px)": {
        fontSize: "16px"
    },
    "@media(max-width: 992px)": {
        fontSize: "20px"
    }
});

const CustomTableCellSecond = styled(TableCell)({
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textTransform: "none",
    color: "#424C53",
    "@media(max-width: 1024px)": {
        fontSize: "16px"
    },
    "@media(max-width: 992px)": {
        fontSize: "16px"
    }
});

const Headings = styled(Typography)({
    color: "#424C53",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    "@media(max-width: 900px)": {
        fontSize: "16px"
    }
});

const MainTableContainer = styled(Table)({
    "@media(max-width:575px)": {
        display: "none"
    }
});

const MobileDetailsContainer = styled(Box)({
    display: "none",
    "@media(max-width: 575px)": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    }
});

const ParaDetails = styled(Typography)({
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textTransform: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#424C53"
});

const DetailedScreenContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "@media(max-width: 992px)": {
        gap: "10px"
    }
});

const SurveyFormHeading = styled(Typography)({
    textAlign: "center",
    color: "#181818",
    fontSize: "30px",
    fontFamily: "Poppins",
    fontWeight: 600,
    marginLeft: "4%",
    marginBottom: "15px",
    marginTop: "15px",
    textTransform: "capitalize",
    "@media(max-width: 992px)": {
        marginLeft: "0%",
        marginTop: "10px",
        fontSize: "18px"
    }
});

const MainContainerDetails = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    padding: "20px 20px 0px 20px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    "@media(max-width: 992px)": {
        width: "auto",
        padding: "20px 0px 0px 0px"
    }
});

const AccordionContainerDetails = styled(Box)({
    display: "flex",
    gap: "2px",
    backgroundColor: "#FFFFFF",
    padding: "20px 0px 0px 0px",
    flexDirection: "column",
    width: "100%",
    "@media(max-width: 992px)": {
        width: "auto"
    }
});

const FieldsName = styled(Typography)({
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#424C53",
    wordBreak: "break-all",
    "@media(max-width: 992px)": {
        fontWeight: 500,
        padding: "0px 10px 0px 10px"
    }
});

const FieldsValueDetails = styled(Typography)({
    fontFamily: "Poppins",
    fontSize: "16px",
    wordBreak: "break-all",
    color: "#424C53",
    fontWeight: 400,
    "@media(max-width: 992px)": {
        padding: "0px 10px 0px 10px"
    }
});

const ImageMainContainer = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "30px",
    marginTop: "10px"
});

const ViewFullImage = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "30px",
    "@media(max-width: 992px)": {
        display: "none"
    }
});

const ViewFullSize = styled(Typography)({
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    backgroundClip: "text",
    webkitBackgroundClip: "text",
    color: "transparent",
    display: "inline",
});

const ViewContainer = styled(Box)({
    display: "flex",
    gap: "3px",
    cursor: "pointer"
});

const FileSizeContainer = styled(Box)({
    width: "60%",
    height: "411px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "@media(max-width: 992px)": {
        width: "100%",
        position: "relative",
        marginTop: "0px",
        borderRadius: "2px"
    }
});

const SurveyHeading = styled(Typography)({
    textTransform: "capitalize",
    textAlign: "center",
    color: "#424C53",
    fontSize: "22px",
    fontFamily: "Poppins",
    fontWeight: 600,
    marginLeft: "4%",
    "@media(max-width: 992px)": {
        marginLeft: "0%",
        marginTop: "10px",
        fontSize: "18px"
    }
});

const styles = () => ({
    root: {
        boxShadow: "none",
        width: "100%",
        border: "none",
        marginBottom: "10px",
        marginTop: "10px",
        "&$expanded": {
            margin: "auto",
            width: "100%",
            border: "none"
        },
    },
    expanded: {},

});

const CustomNewAccordion = withStyles(styles)(Box);

const CustomNewAccordionSummary = styled(AccordionSummary)({
    width: '100%',
    height: "50px",
    border: "1px solid #F9FAFA",
    padding: "0px 0px",
    borderRadius: "4px",
    "& .MuiAccordionSummary-content": {
        justifyContent: "center",
        gap: "30px",
        alignItems: "center",
        fontSize: "25px",
        fontWeight: 400,
        fontFamily: "Poppins",
        "@media(max-width: 992px)": {
            fontSize: "16px"
        },
        "@media(max-width: 425px)": {
            fontSize: "12px",
            gap: "2px"
        }
    },
    "@media(max-width: 435px)": {
        minHeight: '50px !important'
    }
});

const CustomAccordionContainer = styled(Box)({
    width: "80%",
    display: "flex",
    "@media(max-width: 992px)": {
        width: "90%"
    }
});

const BasicContainerAccordion = styled(Box)({
    borderRadius: "16px",
    border: "1px solid transparent",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    padding: "20px 40px 40px 40px",
    backgroundColor: "#FFFFFF",
    width: "91%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 992px)": {
        padding: "0px 0px 10px 0px",
        width: "100%"
    }
});

const CustomAccordionDetails = styled(Box)({
    display: "flex",
    flexDirection: "column"
});

const ImageFiles = styled('img')({
    width: "100%",
    height: "100%",
    borderRadius: "2px",
    objectFit: "contain",
    "@media(max-width: 425px)": {
        height: "240px"
    }
});

const DownloadIconForMobileScreen = styled(Box)({
    display: "none",
    "@media(max-width: 992px)": {
        height: "5%",
        display: "flex",
        padding: "5px 10px 5px 10px",
        background: "#FFFFFFCC",
        position: "absolute",
        zIndex: 999,
        right: 19,
        borderRadius: "2px"
    },
    "@media(max-width: 435px)": {
        padding: "2px 6px 2px 6px"
    }
});

const VideoFiles = styled('video')({
    width: "100%",
    height: "100%",
    borderRadius: "2px"
});

const FilesDescription = styled(Typography)({
    color: "#424C53",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    wordBreak: "break-all",
    "@media(max-width: 992px)": {
        fontSize: "14px",
        padding: "0px 10px 0px 10px"
    }
});

const ImageContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "80%",
    height: "100%"
});

const ImageFile = styled("img")({
    height: "290px",
    width: "100%"
});

const DescriptionDetails = styled(Typography)({
    fontSize: "18px",
    fontFamily: "Poppins",
    color: "#424C53",
    fontWeight: 400
});

const MainFileContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "20px"
});

const DownloadIconSubSectionMobileScreen = styled(Box)({
    display: "none",
    "@media(max-width: 992px)": {
        display: "flex",
        padding: "5px 10px 5px 10px",
        background: "#FFFFFFCC",
        position: "absolute",
        zIndex: 999,
        right: 15,
        borderRadius: "2px"
    },
    "@media(max-width: 435px)": {
        padding: "2px 6px 2px 6px"
    }
});

const CustomModal = styled(Fade)({
    width: "40%",
    height: "50%",
    textAlign: "center",
    display: "flex",
    backgroundColor: "#FFFFFF",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    gap: "30px",
    "@media(max-width: 992px)": {
        height: "40%",
        width: "60%"
    },
    "@media(max-width: 500px)": {
        width: "70%"
    },
    "@media(max-width: 435px)": {
        width: "80%",
        gap: "25px"
    }
});

const CustomModalContainer = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const ErrorText = styled(Typography)({
    fontSize: "25px",
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#000000",
    "@media(max-width: 475px)": {
        fontSize: "18px"
    }
});

const ButtonMainContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "60px",
    "@media(max-width: 435px)": {
        flexDirection: "column",
        gap: "15px",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px"
    }
});

const BackButton = styled(Button)({
    background: "#EBF5FE",
    color: "#0090CF",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    borderRadius: "8px",
    padding: "10px 16px 10px 0px",
    width: "20%",
    textTransform: "none",
    "&:hover": {
        background: "#EBF5FE",
        color: "#0090CF"
    },
    "@media(max-width: 1024)": {
        width: "20%"
    },
    "@media(max-width: 992px)": {
        width: "30%"
    },
    "@media(max-width: 435px)": {
        width: "60%"
    }
});

const NextButton = styled(Button)({
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    fontFamily: "Poppins",
    padding: "10px 0px 10px 40px",
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 500,
    borderRadius: "8px",
    width: "17%",
    "@media(max-width: 1024)": {
        width: "20%"
    },
    "@media(max-width: 992px)": {
        width: "30%"
    },
    "@media(max-width: 435px)": {
        width: "60%"
    }
});

const BackButtonArrow = styled("img")({
    marginLeft: "-60px",
    "@media(max-width: 1300px)": {
        marginLeft: "-30px"
    }
});

const NextButtonArrow = styled("img")({
    paddingLeft: "50px",
    "@media(max-width: 1300px)": {
        marginLeft: "-35px"
    }
});

const ErrorCloseButton = styled(Button)({
    backgroundColor: "#EBF5FE",
    color: "#0090CF",
    fontSize: "16px",
    fontWeight: 500
});
// Customizable Area End
