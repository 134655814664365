import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

// Customizable Area Start
import jsPDF, { HTMLOptions } from "jspdf";
import html2canvas from "html2canvas";
import storage from "../../../framework/src/StorageProvider.web";
import moment from "moment";
import { sendRequest } from "../../forgot-password/src/utils";

export interface ValidResponseType {
  data: object;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface ErrorPayloadType {
  key: string;
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}

export interface NavigationMenuSuccess {
  data: {
  user_survey_navigation_menu: {
    id: number;
    survey_name: string;
    user_survey_sections: Array<NavigationSection>;
    }
  }
}

export interface NavigationSection {
  id: number;
  survey_section_name: string;
  user_survey_sub_sections: Array<NavigationSubSection>;
}

export interface NavigationSubSection {
  id: number;
  survey_sub_section_name: string;
}

export interface NavigationMenuError {
  errors: string;
}

export interface SurveyHistoryInfo {
  data: {
      id: number;
      type: string;
      attributes: {
          id: number;
          survey_name: string;
          date_created: string;
          last_edited: string;
          created_by: string;
          owner: string;
          attached_photo_files: number;
          attached_video_files: number;
          total_uploaded_files: number;
      }
  }
}

export interface SectionDetails {
  data: {
      id: string;
      type: string;
      attributes: {
          id: number;
          user_survey_id: number;
          user_survey_section_name: string;
            is_subsection_present: boolean;
            user_survey_sub_sections: Array<SurveySubSection>;
            survey_template_details: {
                checkbox_1: boolean,
                checkbox_2: boolean
            }
        }
    }
}

export interface SurveySubSection {
  id: number;
  user_survey_sub_section_name: string;
  user_survey_sub_section_details: Array<SurveySubSectionDetails>;
}

export interface SurveySubSectionDetails {
  id: number;
  field_name: string;
  field_value: string;
  field_value_multiple: string[];
  field_type: string;
  field_options: string[];
  photos: Array<MediaFiles>;
  videos: Array<MediaFiles>;
}
export interface MediaFiles {
    id: number;
    filename: string;
    content_type: string;
    description: null;
    created_at: string;
    byte_size: number;
    url: string;
}

interface AccordionOpenState {
  [key: string]: boolean;
}

interface RefObject {
  [key: string]: React.RefObject<HTMLHeadingElement>;
}

interface ActiveLink {
  data: {
      id: string;
      link_share_id: number;
      active_link: string;
  }
}

interface ActiveError {
  errors: string;
}

interface WinZipResponse {
  data: {
      uploaded_files_winzip: string;
  }
}

export interface SaveAsPdfDetails {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      survey_name: string;
      date_created: string;
      last_edited: string;
      created_by: string;
      owner: string;
      progress: string;
      user_survey_sections: Array<SurveySections>;
    }
  }
}

export interface SurveySections {
  id: number;
  user_survey_section_name: string;
  is_survey_subsection_present: boolean;
  user_survey_sub_sections: Array<SurveySubSection>;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  collapsed: boolean;
  openSections: Set<number>;
  navigationMenuSuccess: NavigationMenuSuccess;
  navigationSearchSections:any,
  addSimilarResponse: CreateSurveyUsers;
  openDrawer: boolean;
  surveyInfo: SurveyHistoryInfo;
  sectionDetails: SectionDetails;
  sectionId: number;
  isSelected: boolean;
  sectionSelectedId: number;
  sectionIdOneTime: number;
  accordionOpenState: AccordionOpenState;
  openModal: boolean;
  hasPreviousSection: boolean;
  hasNextSection: boolean;
  winZipLink: string;
  zipLoader: boolean;
  saveAsPdfDetails: SaveAsPdfDetails;
  openPopup: boolean;
  selectedGlobalSearchOption: string | null;
  showPopUp:boolean;
  error:string;
  option:any;
  selectedOption:any;
  subSecName:string;
  userSurveySubSectionId:string;
  searchSpecificSec:string;
  sections: SurveyInfoSection[];
  userSurveyId:string;
  currentSectionId: number;
  specificSecSearchesData:any;


  // Customizable Area End
}


export interface SurveyInfoSection {
  id: number;
  survey_info_section_name: string;
  add_similar_section: boolean;
  add_similar_section_text: string;
  copy_from_last_filled: boolean;
  survey_info_section_fields: SurveyInfoField[];
  newMedia: Array<PhotosNew>;
}

export interface PhotosNew {
  id: number;
  filename: string;
  content_type: string;
  created_at: string;
  byte_size: number;
  url: string;
  media_id: number;
  subSectionNew: string;
  description: string;
  fieldId: number;
}

export interface SurveyInfoField {
  id: number;
  field_name: string;
  field_type: "text" | "numeric" | "select" | "checkbox" | "radio" | "dynamic_dropdown";
  field_options: string[];
  is_mandatory: boolean;
  dynamic_dropdown_type: string;
  is_speech_to_text_required: boolean;
  is_upload_image_video_required: boolean;
}
export type CustomFormLink = Omit<
  SurveyInfoSection,
  "survey_info_section_fields"
> & {
  isSelected: boolean;
};
interface UserSurveySections {
  id: number,
  user_survey_sub_sections: Array<UserSurveySubSections>
  user_survey_section_name: string,
  
}
interface UserSurveySubSections {
  user_survey_sub_section_name: string,
  user_survey_sub_section_details: Array<UserSurveySubSectionDetails>
  id: number,
}
interface UserSurveySubSectionDetails {
  videos: Array<Photos>;
  field_type: string;
  field_options: string[];
  id: number,
  field_name: string,
  field_value: string | null,
  field_value_multiple: string[],
  photos: Array<Photos>;
}

export interface Photos {
  id: number;
  filename: string;
  content_type: string;
  created_at: string;
  byte_size: number;
  url: string;
  description: string | null;
  fieldId?: number;
  subSectionNew?: string;
  media_id: number;
}
interface CreateSurveyUsers {
  data: {
    id: number,
    type: string,
    attributes: {
      id: number,
      survey_template_id: number,
      survey_name: string,
      date_created: string,
      last_edited: string,
      created_by: string,
      owner: string,
      progress: null | string,
      user_survey_sections: Array<UserSurveySections>
    }
  }
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class GuestDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  needsUpdate = false;
  getListOfAllFieldsMessageID = "";
  getTemplatesMessageID = "";
  completeListOfSectionsId:string="";
  getDashboardNavigationMenuApiCallId: string = "";
  getSurveyInfoApiCallId: string = "";
  getSectionDetailsApiCallId: string = "";
  headingRefs: RefObject = {};
  videoRefs: React.RefObject<HTMLVideoElement>[];
  getActiveLinkApiCallId: string = "";
  getWinZipApiCallId: string = "";
  getSaveAsPdfApiCallId: string = "";
  globalSearchMessageId:string = '';
  navigationSearchApiCallId:string="";
  getSectionListId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.videoRefs=[];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      selectedGlobalSearchOption: null,
      collapsed: false,
      navigationSearchSections:[],
      openSections: new Set<number>(),
      navigationMenuSuccess: {
        data: {
        user_survey_navigation_menu: {
          id: 0,
          survey_name: "",
          user_survey_sections: [{
            id: 0,
            survey_section_name: "",
            user_survey_sub_sections: [{
              id: 0,
              survey_sub_section_name: ""
            }]
          }]
        }
      }
      },
      openDrawer: false,
      surveyInfo: {
        data: {
          id: 0,
          type: "",
          attributes: {
            survey_name: "",
            date_created: "",
            last_edited: "",
            id: 0,
            created_by: "",
            owner: "",
            attached_photo_files: 0,
            attached_video_files: 0,
            total_uploaded_files: 0
          }
        }
      },
      sectionDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            user_survey_id: 0,
            user_survey_section_name: "",
            is_subsection_present: false,
            user_survey_sub_sections: [],
            survey_template_details: {
              checkbox_1: false,
              checkbox_2: false
          }
          }
        }
      },
      sectionId: 0,
      isSelected: false,
      sectionSelectedId: 0,
      sectionIdOneTime: 0,
      accordionOpenState: {},
      openModal: false,
      hasPreviousSection: false,
      hasNextSection: false,
      winZipLink: "",
      zipLoader: false,
      addSimilarResponse: {
        data: {
          id: 0,
          type: "",
          attributes: {
            survey_name: "",
            survey_template_id: 0,
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: null,
            id: 0,
            user_survey_sections: [
              {
                user_survey_section_name: "",
                user_survey_sub_sections: [
                  {
                    id: 0,
                    user_survey_sub_section_name: "",
                    user_survey_sub_section_details: [
                      {
                        field_name: "",
                        field_value: null,
                        photos: [],
                        videos:[],
                        field_type: '',
                        id: 0,
                        field_options: [],
                        field_value_multiple: [],
                      }
                    ]
                  }
                ] ,
                id: 0,
              }
            ]
          }
        }
      },
      saveAsPdfDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: "",
            user_survey_sections: []
          }
        }
      },
      openPopup: false,
      showPopUp:false,
      error:"",
      option:[],
      selectedOption:"",
      subSecName:"",
      userSurveySubSectionId:"",
      searchSpecificSec:"",
      sections: [],
      userSurveyId:"",
      currentSectionId: 0,
      specificSecSearchesData:[],


      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getActiveLink();
    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId===this.getListOfAllFieldsMessageID){
        const res={"search_field_details":["Which floor is this MDF located on? (in MDF Info)","Which building is this MDF located in? (in MDF Info)","Property Name (in Property Information)"]}
        this.setState({option:res.search_field_details})
       }
       if (this.isMatchingMessage(this.navigationSearchApiCallId, apiRequestCallId) || this.isMatchingMessage(this.completeListOfSectionsId, apiRequestCallId)) {
        this.handleNavigationOrCompleteListResponse(apiRequestCallId, responseJson);
    }
       if (apiRequestCallId===this.globalSearchMessageId) {
        this.handleGlobalSearchResponse(message);
       }
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }else if (this.isInValidResponse(responseJson)) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

    // Customizable Area Start
    private handleNavigationOrCompleteListResponse(dataMessage: string, successMessage: any): void {
      const successHandler = (success: any) => ({
          [this.navigationSearchApiCallId === dataMessage ? 'navigationSearchSections' : 'listOfSectionsSubsections']: success?.user_survey_navigation_menu?.user_survey_sections,
          showPopUp: false,
      });

      if (this.handleApiResponseNew(successMessage, successHandler)) {
          return;
      }

      if (this.navigationSearchApiCallId === dataMessage) {
          this.getListOfSectionSubSection();
      } else {
          this.sendGetTemplatesRequest();
      }
  }

  sendGetTemplatesRequest = () => {
    const templateId = this.props.navigation.getParam("templateId");
    if (!templateId) {
      return;
    }
    sendRequest(
      `/bx_block_survey/survey_template/survey_template_details?survey_template_id=${templateId}`,
      "GET",
      (id) => (this.getTemplatesMessageID = id),
      null,
      {
        token: localStorage.getItem("auth-token"),
      }
    );
  };

  handleApiResponseNew = (success:any, setStateCallback:any) => {
    if (!success) {
      this.setState({ error: "An unexpected error occurred", showPopUp: true });
      return true;
    }
  
    this.setState(setStateCallback(success));
    return false;
  };

    private isMatchingMessage(messageId: string, dataMessage: string): boolean {
      return messageId === dataMessage;
    }
  guestDashboardApiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailCallBack = (apiRequestCallID: string, responseJSON: ActiveError) => {
    if (apiRequestCallID === this.getActiveLinkApiCallId) {
      this.setState({
      });
   } 

    if (apiRequestCallID === this.getWinZipApiCallId) {
      this.setState({
        winZipLink: responseJSON.errors,
        zipLoader: false
      }, () => {
        this.fileErrorPopup();
      });
   } 
 };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: NavigationMenuSuccess & SurveyHistoryInfo & SectionDetails & ActiveLink & WinZipResponse & SaveAsPdfDetails) => {
    if (apiRequestCallID === this.getDashboardNavigationMenuApiCallId) {
      this.setState({
        navigationMenuSuccess: responseJson,
        sectionId: responseJson.data.user_survey_navigation_menu.user_survey_sections[0].id,
        sectionIdOneTime: responseJson.data.user_survey_navigation_menu.user_survey_sections[0].id
      },()=>{
        this.getSectionsUserDetails();
        
      })
    }

    if (apiRequestCallID === this.getSurveyInfoApiCallId) {
      this.setState({
        surveyInfo: responseJson
      });
   }   
   
    if (apiRequestCallID === this.getSectionDetailsApiCallId) {
      const {checkbox_2} = responseJson.data.attributes.survey_template_details
      const data=responseJson.data.attributes.user_survey_sub_sections
      let sectionData=responseJson
      if(checkbox_2){
          const finalData=data.map(column=>{
            const nonEmtpyFields=column.user_survey_sub_section_details.filter((section)=>{
              return section.field_value!=="" && section.field_value!==null
            })
            column.user_survey_sub_section_details=nonEmtpyFields
            return column
          })
          sectionData.data.attributes.user_survey_sub_sections=finalData
      }
      

      this.setState({
        sectionDetails: sectionData,
        sectionSelectedId: responseJson.data.id,
      });
   } 
   

   if (apiRequestCallID === this.getActiveLinkApiCallId) {
       this.handleCheckLinkShare(responseJson.data.active_link);
   } 

   if (apiRequestCallID === this.getWinZipApiCallId) {
    this.setState({
      winZipLink: responseJson.data.uploaded_files_winzip,
      zipLoader: false
    },()=>{
      this.zipFile();
    });
   } 
   
   if (apiRequestCallID === this.getSaveAsPdfApiCallId) {
     const result = responseJson?.data?.attributes?.user_survey_sections?.flatMap(section => {
      return section.user_survey_sub_sections.flatMap(subSection =>{        
       return subSection.user_survey_sub_section_details.map(field => ({
        user_survey_sub_section_name: subSection.user_survey_sub_section_name,
        field_name: field.field_name,
        field_value: field.field_value,
        id: field.id,
        label: `${field.field_name}(${subSection.user_survey_sub_section_name})`,
        value: section.id,
        sectionId:subSection.id
      
      }))
    }
    )
   } );

    this.setState({
      saveAsPdfDetails: responseJson,
      option:result,
    });
   } 
  };

  getSidebarNavigationData = () => {
    const linkShareId = window.location;
    let arrayPath = linkShareId.pathname.split("/");
    let shareId = arrayPath[arrayPath.length-1]

    this.getDashboardNavigationMenuApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.sidebarNavigationAPiEndPoint}?guest_survey_id=${shareId}`,
    });
  };

  toggleCollapsed = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  toggleSection = (sectionKey: number) => {
    this.setState({
        sectionId: sectionKey,
        hasPreviousSection: false,
        hasNextSection: false
    },()=>{
      this.getSectionsUserDetails();
      this.handlePagination();
    });

    this.setState((prevState) => {
      const openSections = new Set<number>(prevState.openSections);
      if (openSections.has(sectionKey)) {
        openSections.delete(sectionKey);
      } else {
        openSections.add(sectionKey);
      }
      return { openSections };
    });
  };

  handleDrawer=()=>{
    this.setState({
      openDrawer:!this.state.openDrawer
    });
  };

  getSurveyInfoDetails = () => {
    const linkShareNewId = window.location;
    let pathLink = linkShareNewId.pathname.split("/");
    let shareLinkId = pathLink[pathLink.length-1]
    this.getSurveyInfoApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.historyDetailsAPiEndPoint}?guest_survey_id=${shareLinkId}`,
    });
  };

  getSectionsUserDetails = () => {
    const linkShareNewId = window.location;
    let pathLink = linkShareNewId.pathname.split("/");
    let shareLinkId = pathLink[pathLink.length-1];
    this.getSectionDetailsApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.sectionApiEndPoint}?guest_survey_id=${shareLinkId}&guest_survey_section_id=${this.state.sectionId}`
    });
  };

  handleAccordion = (accordionId:  number) => {
    this.setState({
      sectionId: accordionId
    },()=>{
      this.getSectionsUserDetails();
       
    })
    this.setState((prevState) => ({
        accordionOpenState: {
            ...prevState.accordionOpenState,
            [accordionId]: !prevState.accordionOpenState[accordionId],
        },
    }));
  };

  handleSubsectionAccordions = (accordionId:  number, subsectionName: string) => {
    this.setState({
      sectionId: accordionId
    },()=>{
      this.getSectionsUserDetails()
      this.handleScrollToSubSection(subsectionName)
      this.handleAccordion(accordionId); 
    });
  };

  handleScrollToSubSection = (subsectionName: string) => {
    const headingRef = this.headingRefs[subsectionName];
    if (headingRef && headingRef.current) {
        const yOffset = -100;
        const topY = headingRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: topY, behavior: 'smooth' });
    }
  };

  handleViewSize = (viewUrl: string) => {
    window.open(viewUrl);
  };

  handleFullScreen(videoRef: React.RefObject<HTMLVideoElement>) {
    if (videoRef && videoRef.current) {
        const elementVideo = videoRef.current;
        if (elementVideo.requestFullscreen) {
          elementVideo.requestFullscreen();
        }
      }
  };

  getActiveLink = () => {
    const linkShareNewId = window.location;
    let pathLink = linkShareNewId.pathname.split("/");
    let activeLinkId = pathLink[pathLink.length-1];
    this.getActiveLinkApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.activeLinkEndPoint}${activeLinkId}`
    });
  };

  handleCheckLinkShare = (activeLink: string) =>{
    let newPath =  window.location.href;
      this.setState({
        openModal: false
      },() => {
        this.getSidebarNavigationData();
        this.getSurveyInfoDetails();
        this.getSectionsUserDetails();
        this.handlePagination();
        this.getSavePdfDetails();
      });
  };

  nextPagination = () => {
    const { navigationMenuSuccess, sectionId } = this.state;
    const sectionIndex = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.findIndex(
      (section) => section.id === sectionId
    );
    if (sectionIndex < navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.length - 1) {
      const nextSectionId = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections[sectionIndex + 1].id;
      this.setState(
        { sectionId: nextSectionId, hasNextSection: false, hasPreviousSection: false },
        () => {
          this.getSectionsUserDetails();
          this.handlePagination();
        }
      );
    } else {
      this.setState({ hasNextSection: true });
    }
  };

  previousPagination = () => {
    const { navigationMenuSuccess, sectionId } = this.state;
    const sectionIndex = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.findIndex(
      (section) => section.id === sectionId
    );
    if (sectionIndex > 0) {
      const previousSectionId = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections[sectionIndex - 1].id;
      this.setState(
        { sectionId: previousSectionId, hasNextSection: false, hasPreviousSection: false },
        () => {
          this.getSectionsUserDetails();
          this.handlePagination();
        }
      );
    } else {
      this.setState({ hasPreviousSection: true });
    }
  };

  handlePagination = () => {
    const { navigationMenuSuccess, sectionId } = this.state;
    const sectionIndex = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.findIndex(
      (section) => section.id === sectionId
    );
    if (sectionIndex === 0) {
      this.setState({
        hasPreviousSection: true,
        hasNextSection: false
      });
    } else if (sectionIndex === navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.length - 1) {
      this.setState({
        hasPreviousSection: false,
        hasNextSection: true
      });
    } else {
      this.setState({
        hasPreviousSection: false,
        hasNextSection: false
      });
      }
  };

  getDownloadWinZipFile = () => {
    const linkSharePath = window.location;
    let pathName = linkSharePath.pathname.split("/");
    let activeLinkId = pathName[pathName.length - 1];
    this.getWinZipApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.winZipFileEndPoint}${activeLinkId}`
    });
  };

  downloadZip = () => {
    this.setState({
      zipLoader: true
    }, () => {
      this.getDownloadWinZipFile();
    });
  };

  zipFile = () => {
    window.location.href = this.state.winZipLink;
  };

  getSavePdfDetails = () => {
    const linkPath = window.location;
    let savePathName = linkPath.pathname.split("/");
    let saveId = savePathName[savePathName.length - 1];
    this.getSaveAsPdfApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.saveAsPdfEndPoint}${saveId}`
    });
  };

  downloadPDF = async() => {
    const linkPath = window.location;
    let savePathName = linkPath.pathname.split("/");
    let pdfSurveyId = savePathName[savePathName.length - 1];
    let absolutePdfUrl = `${configJSON.savePdfUrl}${pdfSurveyId}`;
    let openPdfReportUrl = `${window.location.origin}/${absolutePdfUrl}`;
    window.open(openPdfReportUrl, "_blank");
  };

  fileErrorPopup = () => {
    this.setState({
      openPopup: !this.state.openPopup
    });
  };

  handleDownloadImage = (imageUrl: string, filename:string) => {
    fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };
  handleFormateDate = (date:string) =>{
    return moment(date).format('MM/DD/YYYY') 
   }
   extractFromQueryParams(param: string) {
    const sectionId = this.props.navigation.getParam(param);
    
    return sectionId;
  }

  getListOfSectionSubSection = async()=>{
    const {navigationSearchSections, userSurveySubSectionId} = this.state;
    const linkShareId = window.location;
    let arrayPath = linkShareId.pathname.split("/");
    let sectionId = this.extractFromQueryParams("sectionId");
    const token = await storage.get("auth-token")
    if(navigationSearchSections && userSurveySubSectionId){
    this.setState({showPopUp:false})
    sendRequest(
      `bx_block_survey/users_survey/user_survey_section_details?user_survey_section_id=${sectionId}&user_survey_id=${this.state.userSurveyId}&user_survey_sub_section_id=${1380}`,
      "GET",
      (id) => (this.getSectionListId = id),
      null,
      {
        token,
      }
    );
    } else return;
    };

    handleScrollToHeading = (subsectionName: string) => {
      const headingRef = this.headingRefs[subsectionName];
      if (headingRef && headingRef.current) {
        headingRef.current.scrollIntoView()
    }
    };
    
    private handleGlobalSearchResponse(responseJson: Message): void {
      const res={"search_field_details":"Property Information", "user_survey_section_id":1377,"user_survey_sub_section_name":"Which floor is this MDF located on?"}
            const sectionName = res.search_field_details;
            let sectionId;
            let subsectionNavigateField;
            if (sectionName) {
                sectionId = res.user_survey_section_id;
            } else {
                sectionId = res.user_survey_section_id;
                subsectionNavigateField=res.user_survey_sub_section_name;
            }
            this.onLickClick(sectionId,subsectionNavigateField);
            }

  onLinkClick = async(sectionId: number) => {
    this.setState({
      hasPreviousSection: false,
      sectionId: sectionId,
      hasNextSection: false
  },()=>{
    this.getSectionsUserDetails();
    this.handlePagination();
  });
    this.setState((prevState) => {
      const openSections = new Set<number>(prevState.openSections);
      if (openSections.has(sectionId)) {
        openSections.delete(sectionId);
      } else {
        openSections.add(sectionId);
      }
      return { openSections };
    });
    this.setState({
      searchSpecificSec:""
    });
  }

  onLickClick = async(sectionId: number,subsectionNavigateField?:string) => {
    this.needsUpdate = true;
    this.setState({sectionSelectedId: sectionId, sectionIdOneTime: sectionId })
    this.setState({
      sectionId: sectionId,
      hasNextSection: false,
      hasPreviousSection: false
    },()=>{
      this.handlePagination();
      this.getSectionsUserDetails();
    });
    this.setState((prevState) => {
      const openSections = new Set<number>(prevState.openSections);
      if (openSections.has(sectionId)) {
        openSections.delete(sectionId);
      } else {
        openSections.add(sectionId);
      }
      return { openSections };
    });
    this.setState({
      searchSpecificSec:""
    });
    this.setState((prevState) => ({
      accordionOpenState: {
          [this.state.selectedOption.sectionId]: true,
      },
  }));
    setTimeout(()=> { subsectionNavigateField && this.handleScrollToHeading(subsectionNavigateField)},1000)
  };
  navigationSearchWithinSection = async()=>{
    const {searchSpecificSec} = this.state;
    const linkShareId = window.location;
    let arrayPath = linkShareId.pathname.split("/");
    let shareId = arrayPath[arrayPath.length-1]
    if(searchSpecificSec.length > 0)
    {const token = await storage.get("auth-token")
      sendRequest(
        `/bx_block_survey/users_survey/user_survey_section_navigation?user_survey_id=${shareId}&search_query=${searchSpecificSec}`,
        "GET",
        (id) => (this.navigationSearchApiCallId = id),
        null,
        {
          token,
        }
      );}
    else {
      this.setState({ navigationSearchSections: [] });
    }
    };

  handleSectionSearchBar = (e:any)=>{
    this.setState({
      searchSpecificSec:e.target.value,
    },()=>{
      this.filterSpecificSections()
      this.navigationSearchWithinSection()
      this.getListOfSectionSubSection()
    }
    )
  }
  

  get customFormLinks(): CustomFormLink[] {
    return this.state.sections.map((section) => ({
      ...section,
      isSelected: section.id === this.state.currentSectionId,
    }));
  }

  filterSpecificSections = () => {
    const { searchSpecificSec } = this.state;
    const filteredSections = this.customFormLinks.filter((section: any) =>
      section.survey_info_section_name
        .toLowerCase()
        .includes(searchSpecificSec.toLowerCase())
    );

    this.setState({ specificSecSearchesData:filteredSections });
  };

   handleGlobalSearch = async (option: any) => {
    const linkShareId = window.location;
    let arrayPath = linkShareId.pathname.split("/");
    const sectionName = this.state.selectedOption.user_survey_sub_section_name;
    let sectionId;
    let subsectionNavigateField;
        sectionId = this.state.selectedOption.value;
        subsectionNavigateField=this.state.selectedOption.user_survey_sub_section_name;
    this.onLickClick(sectionId,subsectionNavigateField);
  }


 
  handleEror = (error: string) => {
    this.setState({
      showPopUp: true,
      error,
    })
  }

  get fieldValue() {
    if (!this.state.selectedOption) {
      return null;
    }
    return this.state.selectedOption;
  }

  handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    this.setState(
      {
        selectedOption: value,
      },
      () => {
        this.search();
        this.clearSelectedOption();
      }
    );
  };

  search = () => {
    if (this.state.selectedOption) {
      this.handleGlobalSearch(this.state.selectedOption);
    }
  };

  private clearSelectedOption = () => {
    return setTimeout(() => this.setState({ selectedOption: "" }), 100);



  };

 
  filterOptions = (result: any, state: { inputValue: string }) => {
    const query = state.inputValue.toLowerCase();
    if(!query.length ) 
      return result

    const handleOptions = result.filter((option:any) =>
        option.field_name?.toLowerCase()?.includes(query) ||
      option.field_value?.toLowerCase()?.includes(query) ||
      option.user_survey_sub_section_name?.toLowerCase()?.includes(query)
    );
    
    return handleOptions;
  };

    // Customizable Area End
  
}