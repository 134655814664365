// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import React, { ChangeEventHandler, FC } from "react";
import { PhotoInput } from "./PhotoInput.web";
import CheckIcon from "@material-ui/icons/Check";
import { deletePhotoImg, editImg } from "./assets";
import { ErrorMessage } from "../../forgot-password/src/ErrorMessage";
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  onFileUpload: ChangeEventHandler<HTMLInputElement>;
  src: string;
  error: string;
  success: boolean;
  onFileDelete: () => void;
  onEditPhotoClick: () => void;
}
const PhotoFormWrapper = styled(Box)({
  flex: 1,
  "@media(max-width: 550px)": {
    display: "flex",
    flexDirection: "column"
  }
});
export const SectionTitle = styled(Typography)({
  fontSize: "18px",
  fontWeight: 600,
  fontFamily: "Poppins, Inter, sans-serif",
  color: "rgba(66, 76, 83, 1)",
  minWidth: "205px",

  "@media(max-width: 900px)": {
    display: "none"
  }
});

const PhotoSection = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: "70px",
  marginTop: "30px",
  "@media(max-width: 550px)": {
    gap: "0px",
    margin: "30px 0px 35px 15px"
  }
});

const ActionButtonsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  alignItems: "center",
  color: "rgba(79, 195, 253, 1)",
  fontFamily: "Poppins, Inter, sans-serif",

  "@media(max-width: 650px)": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "rgba(79, 195, 253, 1)",
    fontFamily: "Poppins, Inter, sans-serif"
  }
});

export const ActionText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  marginLeft: "13px"
});

const ActionTextHint = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "rgba(192, 200, 205, 1)",
  marginLeft: "-14px",
  paddingBottom: "35px",
  fontFamily: "Poppins, Inter, sans-serif",

  "@media(max-width: 650px)": {
    paddingBottom: "unset",
    marginLeft: "unset"
  },
  "@media(max-width: 550px)": {
    marginLeft: "30px",
    marginBottom: "7px"
  },
  "@media(max-width: 320px)": {
    marginLeft: "5px"
  }
});

const TitleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between"
});

const MessageWrapper = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  width: "400px"
});

const EditIconTextMainBox = styled(Box)({
  "@media(max-width:550px)": {
    display: "flex"
  }
});

const EditDeleteImageMainBox = styled(Box)({
  "@media(max-width:550px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignSelf: "flex-start",
    marginLeft: "30px"
  },
  "@media(max-width: 320px)": {
    marginLeft: "5px"
  }
});

export class PhotoForm extends BlockComponent<Props, {}, {}> {
  render(): React.ReactNode {
    const {
      onFileUpload,
      src,
      error,
      success,
      onFileDelete,
      onEditPhotoClick
    } = this.props;
    return (
      <PhotoFormWrapper>
        <TitleWrapper>
          <SectionTitle>Your profile photo</SectionTitle>
          <MessageWrapper>
            <ErrorMessage errorMessage={error} showError={!!error} />
            {success && (
              <Box
                color="#34D399"
                display={"flex"}
                flexDirection={"row"}
                width={"100%"}
                alignItems="center"
                justifyContent={"end"}
              >
                <Typography
                  style={{
                    fontWeight: 500,
                    fontFamily: "Poppins, Inter, sans-serif"
                  }}
                >
                  Your changes have been saved
                </Typography>
                <CheckIcon style={{ paddingBottom: "7px" }} />
              </Box>
            )}
          </MessageWrapper>
          <div />
        </TitleWrapper>
        <PhotoSection>
          <PhotoInput imgSrc={src} onImageUpload={onFileUpload} />
          <ActionButtonsWrapper>
            <EditDeleteImageMainBox
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <EditIconTextMainBox
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
                style={{ cursor: "pointer" }}
                marginBottom={"10px"}
                alignItems="center"
                onClick={onEditPhotoClick}
                data-test-id={"editBtnText"}
              >
                <img src={editImg} alt="edit" height="18px" width="18px" />
                <ActionText>Edit</ActionText>
              </EditIconTextMainBox>
              <Box
                style={{ cursor: "pointer" }}
                display={"flex"}
                alignItems="center"
                flexDirection={"row"}
                justifyContent={"flex-start"}
                onClick={onFileDelete}
                data-test-id={"delete-btn"}
              >
                <img
                  src={deletePhotoImg}
                  style={{ height: "18px", width: "18px" }}
                />
                <ActionText>Delete</ActionText>
              </Box>
            </EditDeleteImageMainBox>
            <ActionTextHint>(Maximum image size is 5MB)</ActionTextHint>
          </ActionButtonsWrapper>
        </PhotoSection>
      </PhotoFormWrapper>
    );
  }
}
// Customizable Area End
