Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.CommonAreasHeading = "Building Information ";
exports.NameNumber = "Building Name";
exports.placeholderTextField = "Enter text here";
exports.uploadImageVideo = "Upload image/video";
exports.placeholderFileUpload = "Upload image/video";
exports.newCablingForWAP = "How to install new cabling for WAP's";
exports.addFloor = "Add floor";
exports.copyFilled = "Copy from last filled?";
exports.yes = "Yes";
exports.patchUserApiEndPoint = "bx_block_survey/users_survey";
exports.patchUserApiMethod = "PATCH";
exports.autoSaveUserApiEndPoint = "bx_block_survey/users_survey/auto_save_survey_details";
exports.createSurveyApiEndPoint = "bx_block_survey/users_survey";
exports.deleteSubsectionApiMethod = "DELETE";
exports.createSurveyApiEndPoint = "bx_block_survey/users_survey";
exports.deleteSubsectionApiEndpoint = "bx_block_survey/users_survey/remove_similar_section_details"
exports.addSimilarSectionsApiEndPoint = "bx_block_survey/users_survey/add_similar_section_details";
exports.uploadNewPhotoVideo = "Upload New Files (photos/videos)";
exports.uploadNewPhotoVideoMobile = "Upload new photos/videos";
exports.selectFile = "Select File";
exports.cancel = "Cancel";
exports.fileName = "Name";
exports.mbName = "Mb";
exports.progressBarUploads = "You have reached 10 uploads";
exports.uploadDocuments = "Upload Documents";
exports.successMessage = "Successful uploaded!";
exports.speed = "120KB/Sec";
exports.deleteImage = "Delete";
exports.videoNotSupported = "Your browser does not support the video tag.";
exports.getFieldsDetailsAPIEndPoint = "bx_block_survey/users_survey/template_section_field_user_survey_mapping?survey_template_id=1447&user_survey_id=227304&user_survey_section_id=88571&user_survey_sub_section_id=44349&template_field_id=318";
exports.bulkUploadingApiEndPoint = "bx_block_survey/user_survey_media";
exports.uploadApiContentType = "multipart/form-data";
exports.uploadNewPhotoVideoTitle = "Upload New photos/videos";
exports.allowGalleryaccessText = "Allow Gallery Access";
exports.allowAcessParaText = "Gallery access is required to upload photos and videos to your surveys";
exports.allowAcessText = "Allow Access";
exports.AccessDeniedText = "Don't Allow Access";
exports.ReturnHomeText = "Return to HomePage";
exports.allowCameraAccessText = "Allow Camera Access"
exports.uploadDocuments = "Upload Documents";
exports.continue = "Continue";
exports.fromCameraText = "From Camera";
exports.fromGalleryText = "From Gallery";
exports.noGalleryAccess = "No Gallery Access ";
exports.noCameraAccess = "No Camera Access ";
exports.galleryAccessDeniedDetail = "We don't have access to the gallery. We need access to upload files.";
exports.cameraAccessDetailText = "Gallery access is required to upload photos and videos to your surveys";
exports.cameraDeniedDetailText = "We don't have access to the camera. We need access to perform this action.";
exports.surveyName = "Survey Name *";
exports.propertyName = "Property Owner *";
exports.surveyTemplate = "Survey Template";
// Customizable Area End