//Cuszomizable Area Start
import React from "react";
import ContentWrapper, {
  Props as ContentWrapperProps,
} from "./ContentWrapper.web";
import { MainContent } from "./Account.web";
import { SettingsTitle } from "./SettingTitle";
import { SaveButton } from "./AccountDetailsSection.web";
import { ForgetPasswordText } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { Box, styled } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props extends ContentWrapperProps {
  error: string;
  success: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  children: any;
  goBack: () => void;
  subtitle: string;
  navigation: any;
  saveBtnText?: string;
  cancelButtonText?: string;
  isPasswordChanged?:boolean;
}

const ButtonBox = styled(Box)({
  padding: "24px",
  marginTop: "auto"
})

const ResetText = styled(ForgetPasswordText)({
  marginBottom: "0",
  marginTop: "20px",
});

const SubmitButton = styled(SaveButton)({
  maxWidth: "unset",
  marginTop: "550px",
  marginLeft: 0,
});

const ContinueButton = styled(SaveButton)({
  maxWidth: "unset",
  marginTop: "0px",
  marginLeft: 0,
})
export class MobileSettingWrapper extends BlockComponent<Props, {}, {}> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      children,
      account,
      error,
      success,
      onSubmit,
      goBack,
      onCancel,
      subtitle,
      navigation,
      saveBtnText,
      cancelButtonText,
      isPasswordChanged
    } = this.props;
    return (
      <ContentWrapper account={account} navigation={navigation}>
        <MainContent>
          <SettingsTitle
            subTitle={subtitle}
            error={error}
            success={success}
            onTitleClick={goBack}
            navigation={navigation}
          />
          {children}
          <ButtonBox>
            {
              isPasswordChanged ?
                <ContinueButton onClick={()=> navigation.navigate("UserProfileAdvance")}>Continue</ContinueButton>
              :
              <>
            <SubmitButton data-test-id="save" onClick={() => onSubmit()}>
              {saveBtnText ?? 'Save changes'}
            </SubmitButton>
            <ResetText data-test-id="cancel" onClick={onCancel}>
             {cancelButtonText ?? 'Dismiss changes'}
            </ResetText>
              </>
            }
          </ButtonBox>
        </MainContent>
      </ContentWrapper>
    );
  }
}
//Cuszomizable Area End
